<script>
export default { name: 'PollutionMap' }
</script>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount, defineExpose } from 'vue'

import { CustomMarker, GoogleMap } from 'vue3-google-map'
import { darkStyle } from '@/modules/GoogleMapStyle.js'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import RegionFloatingBox from '@/components/RegionFloatingBox.vue'

import { StationsData } from '@/state/StationsData.js'
import { RegionsData } from '@/state/RegionsData.js'
import { SelectedStation } from '@/state/SelectedStation.js'
import { uaqiColors } from '@/modules/UAQI.js'
import { getStationIcon } from '@/modules/StationIcon.js'
import { isStationsInMapBounds, zoomToUkraine } from "@/modules/Geo.js"

// Default map zoom and center point
const defaultCenter = { lat: 50.449399, lng: 30.523335 }
const defaultMapZoom = 10

// When true, regions is shown and markers is minimized
const regionsMode = ref(false)

// Reference to Google Map object
const mapRef = ref(null)

// Region under the mouse
const selectedRegion = ref(null)

// Show regions on map
function showRegions(show)
{
	console.log("SHOW REGIONS")

	const data = RegionsData.regions

	mapRef.value.map.data.setStyle(function (feature) {
		// Get region from data
		const region = data.find(r => r.name === feature.getProperty("name"))
		
		// Get level and color
		const level = region ? region.level : 0
		const color = level != 0 ? uaqiColors[level] : "black"

		// Set level to properties
		feature.setProperty("level", level)

		return {
			fillColor: color,
			strokeWeight: 1,
			strokeColor: "#dadada",
			visible: show
		};
	});
}

// Show regions on zoomout state
watch(regionsMode, (regionsMode) => {
	console.log("Regions mode changed:  " + regionsMode)
	showRegions(regionsMode)
})

// Filtered stations to show on map
const stationsToShow = ref([])

function filterStationsToShow() {
	const all = StationsData.stations

	if (all == null) {
		stationsToShow.value = [ ]
	} else {
		stationsToShow.value = all.filter(e => isStationsInMapBounds(mapRef.value.api, mapRef.value.map, e))
	}
}

function arrayRemoveItemAll(arr, value) {
	var i = 0;
	while (i < arr.length) {
		if (arr[i] === value) {
			arr.splice(i, 1);
		} else {
			++i;
		}
	}
	return arr;
}

const updatedStations = ref([])

watch(StationsData, () => {
	filterStationsToShow()
})

let oldData = [ ]

watch(StationsData, () => {
	const newData = StationsData.stations

	if (newData == null) return

	newData.find((n) => {
		// Find in old data
		const old = oldData.find(o => o.id == n.id)

		if (old == null || old.last_online != n.last_online) { 
			updatedStations.value.push(n.id)

			setTimeout(() => { 
				arrayRemoveItemAll(updatedStations.value, n.id)
			}, 500)
		}
	})

	oldData = newData
})

// On component mounted
onMounted(() => {
	console.log("MAP component mounted")

	// Update data if needed
	if (StationsData.isUpdateNeeded()) StationsData.update()

	// Set in use to auto update stations
	StationsData.setInUse("map")
})

onBeforeUnmount(() => {
	StationsData.unsetInUse("map")
})

// Watch for "ready" then do something with the API or map instance
watch(() => mapRef.value?.ready, (ready) => {
	if (!ready) return

	console.log("GOOGLE MAP is ready")

	// Get map 
	const map = mapRef.value.map

	map.addListener("bounds_changed", () => { filterStationsToShow() })

	// Set markers to minimized and show regions
	map.addListener("zoom_changed", () => {
		regionsMode.value = (map.getZoom() < 8)
	})

	// When the user hovers, tempt them to click by outlining the regions
	map.data.addListener('mouseover', function (event) {
		// Call revertStyle() to remove all overrides. This will use the style 
		// rules defined in the function passed to setStyle()
		map.data.revertStyle()
		
		// Override stroke value
		map.data.overrideStyle(event.feature, { strokeWeight: 4 })

		selectedRegion.value = { 
			name: event.feature.getProperty("name"),
			level: event.feature.getProperty("level")
		}
	})

	map.data.addListener('mouseout', function (event) {
		map.data.revertStyle()

		// Hide region info box
		selectedRegion.value = null
	})

	// Region onclick
	map.data.addListener('click', function (event) {
		// Get bounds of region
		const bounds = new mapRef.value.api.LatLngBounds()
		event.feature.getGeometry().forEachLatLng(function (latlng) {
			bounds.extend(latlng)
		})

		// Zoom to bounds
		map.fitBounds(bounds)

		// Zoomin more if needed
		const listener = mapRef.value.api.event.addListenerOnce(map, 'bounds_changed', function () {
			if (map.getZoom() < 8) map.setZoom(9)
		})

		// Hide region info box
		selectedRegion.value = null
	})

	// Set last zoom to default
	var lastZoom = defaultMapZoom

	map.addListener("zoom_changed", () => {
		let currentZoom = map.getZoom()

		// Zoom to full Ukraine
		if (lastZoom > currentZoom && currentZoom < 8 && currentZoom > 6)
		{
			console.log("Zoomout detected")
			zoomToUkraine(mapRef.value.api, map)
		}

		lastZoom = currentZoom
	})

	// Get last map position
	const lastPositionJSON = localStorage.getItem('lastPosition')

	if (lastPositionJSON != null) 
	{ 
		const lastPosition = JSON.parse(lastPositionJSON) 
		map.panTo(lastPosition.location)
		map.setZoom(lastPosition.zoom)
	} 
	else 
	{
		// Trigger all of the event listeners
		map.panTo(defaultCenter)
		map.setZoom(defaultMapZoom)
	}

	map.addListener('tilesloaded', function (event) {
		const position = { location: map.getCenter(), zoom: map.getZoom() }
		localStorage.setItem('lastPosition', JSON.stringify(position))
	})

	// Setup regions view
	setupRegions()
})

async function setupRegions() {
	// Run Region data update
	await RegionsData.getUpdated()

	// Load GeoJSON of ukraine regions
	console.log("Loading regions graphics")
	mapRef.value.map.data.loadGeoJson("ukraine_regions.json")

	// Hide regions if not a regions mode
	showRegions(regionsMode.value)
}

/**
 * Pan map to object location.
 * Function to call from other modules (Search)
 * 
 * @param {Object} location Google Maps location object (`{ lat: 50, lng: 30 }`)
 */
function panToLocation(location)
{
	const map = mapRef.value.map
	if (map == null) return

	map.panTo(location)
	map.setZoom(12)
}

defineExpose({ panToLocation });
</script>

<template>
	<GoogleMap 
		ref="mapRef"
		api-key="AIzaSyBgVH0fE8NBe3dgXJzCU9v3be8Q0IcOvQM" 
		style="width: 100%; height: 100%" 
		:disableDefaultUi=true
		:clickableIcons=false
		:isFractionalZoomEnabled=true
		:backgroundColor="'#212121'"
		:minZoom=5
		:styles="darkStyle"
		:center="defaultCenter" 
		:zoom="defaultMapZoom"
	>
		<CustomMarker 
			v-for="station in stationsToShow"
			:key="station.id"
			:options="{ 
				position: { lat: station.latitude, lng: station.longitude }, 
				zIndex: SelectedStation.isSelected(station) ? 10 : station.current_level,
				anchorPoint: 'CENTER'
			}"
			@click="regionsMode ? null : SelectedStation.set(station)"
		>
			<div 
				class="station-marker" 
				v-bind:class="{ 
					mini: regionsMode, 
					selected: SelectedStation.isSelected(station),
					updated: updatedStations.some(e => e == station.id) 
				}"
				:style="{ backgroundColor: uaqiColors[station.current_level] }"
			>
				<FontAwesomeIcon v-if="station.type != 'outdoor' || station.service" :icon="getStationIcon(station)"/>
			</div>
		</CustomMarker>
	</GoogleMap>

	<RegionFloatingBox v-if="regionsMode && selectedRegion != null" :region="selectedRegion"/>
</template>

<style scoped>

.station-marker {
	background-color: gray; 
	
	border-radius: 20px; 
	border: 5px solid var(--color-station-outline);
	
	width: 20px; 
	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	overflow: hidden;

	transition: width 0.3s ease,
				height 0.3s ease,
				border-width 0.3s ease,
				border-color 0.3s ease,
				transform 0.3s ease;
}

.station-marker.selected {
	border-color: var(--color-station-outline-active);
}

.station-marker.mini {
	width: 5px !important; 
	height: 5px !important;

	border-width: 0px !important;
}

.station-marker.mini > svg { 
	display: none;
}

.station-marker.updated {
	transform: scale(1.2);
}
</style>
