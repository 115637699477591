<template>
	<div class="stationListItem">
		<div class="infoContainer" style="display: flex; align-items: center">
			<FontAwesomeIcon :icon="getStationIcon(station)" />
			<div style="margin-left: 1em;">
				<div style="font-weight: bold;">{{ station.name }}</div>
				<small>{{ station.address }}</small>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineProps, computed } from 'vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { uaqiColors } from '@/modules/UAQI.js'
import { getStationIcon } from '@/modules/StationIcon.js'

const props = defineProps({
	station: {
		type: Object,
		required: true
	}
})

const levelColor = computed(() => {
	return uaqiColors[props.station.level]
})

const levelColorDim = computed(() => {
	return levelColor.value + "40"
})
</script>

<style scoped>
.stationListItem {
	background-color: var(--color-bg);
	border-radius: 10px;
	padding: 1em;

	border: 1px solid v-bind(levelColor);

	display: flex;

	user-select: none;
	cursor: pointer;

	overflow: hidden;

	-webkit-tap-highlight-color: transparent;

	transition: background-color 0.3s ease-in-out,
				box-shadow 0.5s ease-in-out,
				translate 0.3s ease-out;
}

.stationListItem:hover {
	background-color: v-bind(levelColor + "40");
	box-shadow: 0 2px 10px var(--color-bg);
	translate: 0 -2px;
}

.infoContainer {
	background: linear-gradient(to right, v-bind(levelColorDim), #00000000);
	margin: -1em;
	padding: 1em;
}
</style>