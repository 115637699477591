<template>
	<div class="contentContainer">
		<LoadingScreen :loadingStatus="dataLoaded" />
		<GradientBackground :level="region.level"/>
		<div class="content">
			<div class="topControls">
				<div>
					<button class="btn" @click="router.push({ name: 'stats' })">
						<FontAwesomeIcon :icon="faAngleLeft" />
						<span>Назад</span>
					</button>
				</div>
				<div class="navigationControls">
					<button class="btn" @click="goToSection(sectionRealtime)">
						<FontAwesomeIcon :icon="faCircleDot" :fade="true" />
						<span>Поточні дані</span>
					</button>
					<button class="btn" @click="goToSection(sectionStatistics)">
						<FontAwesomeIcon :icon="faChartLine" />
						<span>Статистика</span>
					</button>
					<button class="btn" @click="goToSection(sectionStations)">
						<FontAwesomeIcon :icon="faListUl" />
						<span>Станції</span>
					</button>
				</div>
			</div>
			<br />
			<h1>{{ region.name }}</h1>
			<div>
				<div v-if="region.online_stations > 0">
					Наразі працює {{ getStationWord(region.online_stations) }} моніторингу
				</div>
				<div v-else>
					На жаль, наразі в регіоні не працює ні одна станція і це дуже сумно...
				</div>
			</div>
			<br /><br />
			<div v-if="region.online_stations > 0" ref="sectionRealtime">
				<h2>Зараз повітря {{ uaqiDescriptions[region?.level]?.air }}</h2>
				<div class="textDim">
					<p>{{ uaqiDescriptions[region?.level]?.description }}</p>
				</div>
				<div class="pollutantsContainer">
					<PollutantValue 
						v-for="p in pollutantsFiltered"
						:key="p"
						:pollutant=p
					/>
				</div>
				<h2>Рекомендації</h2>
				<p>
					<button 
						class="btn" 
						style="margin-right: 0.5em;"
						:class="{ active: recommendationsType == 'all' }"
						@click="recommendationsType = 'all'"
					>
						Для всіх громадян
						<FontAwesomeIcon :icon="faUserGroup" />
					</button>
					<button 
						class="btn"
						:class="{ active: recommendationsType == 'risk' }"
						@click="recommendationsType = 'risk'">
						Для груп ризику
						<FontAwesomeIcon :icon="faPersonCane" />
					</button>
				</p>
				<div class="uaqiInfo">
					<div 
						class="infoPanel"
						v-for="r in recommendationsTemplate"
						:key="r"
					>
						<div>
							<h3>
								<FontAwesomeIcon :icon="r.icon" />
								{{ r.name }}
							</h3>
						</div>
						<div class="textDim">
							<small>
							<p 
								style="margin-top: 0.2em; margin-bottom: 0.5em;"
								v-for="l in r.recommendations.split('\n')" :key="l"
							>
								• {{ l }}
							</p>
							</small>
						</div>
					</div>
				</div>
			</div>
			<br/>
			<div ref="sectionStatistics">
				<h2>Статистика</h2>
				<div>
					
					<div>
						<h3>Графік забруднення за останні 30 днів</h3>
	
						<div class="typeSelectButtons">
							<button
								class="btn outline"
								v-for="p in region.pollutants?.filter(e => pollutantsToHide.includes(e.type) == false)"
								:key="p"
								:class="{ active: selectedGraph.type == p.type && selectedGraph.unit == p.unit}"
								@click="selectedGraph = p"
							>
								{{ p.type }}
							</button>
						</div>
	
						<div>
							<PlotlyChart 
								:data=dataMonth
								:title="'-'"
								:layout="{ yaxis: { title: dataMonthTitle } }"
								:fixedRange=true
								style="height: 18em;"
							/>
						</div>
					</div>

					<br/>

					<div>
						<h3>Календар забруднення за рік</h3>

						<div class="typeSelectButtons">
							<button
								class="btn outline"
								v-for="t in calendarTypes"
								:key="t"
								:class="{ active: calendarType.type == t.type }"
								@click="calendarType = t"
							>
								{{ t.name }}
							</button>
						</div>

						<div style="margin-top: 1em;">
							<PollutionCalendar :data="calendarData" :type="calendarType.type" />
						</div>
					</div>
				</div>
			</div>
			<br/>
			<div ref="sectionStations">
				<h2>Станції</h2>
				<p>Всього в регіоні встановлено {{ getStationWord(region.installed_stations) }} </p>
				<div class="row" v-if="region.installed_stations > 0">
					<div class="col-xs-12 col-sm-6" style="margin-bottom: 0.5em;">
						<div class="stationList" style="height: 20em;">
							<StationListItem
								v-for="station in region.stations"
								:key="station.id"
								:station="station"
								style="margin-bottom: 0.5em;"
								@mouseover="hoveredStationId = station.id" 
								@click="router.push({ name: 'station', params: { id: station.id } })"
							>	
							</StationListItem>
						</div>
					</div>
					<div class="col-xs-12 col-sm-6" style="height: 20em;">
						<RegionMap 
							v-if="dataLoaded"
							:stations="region.stations" 
							:hoveredStationId="hoveredStationId"
							style="border-radius: 1em; overflow: hidden;"
						/>
					</div>
				</div>
			</div>
			<br/><br/><br/><br/>
		</div>
	</div>
</template>

<script setup>
import { ref, defineProps, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faPersonCane } from '@fortawesome/free-solid-svg-icons'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { faCircleDot } from '@fortawesome/free-solid-svg-icons'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { faListUl } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faTreeCity } from '@fortawesome/free-solid-svg-icons'
import { faLungsVirus } from '@fortawesome/free-solid-svg-icons'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

import { uaqiColors } from '@/modules/UAQI.js'
import { uaqiDescriptions } from '@/modules/UAQI.js'
import { getStationWord } from '@/modules/Language.js'

import LoadingScreen from '@/components/LoadingScreen.vue'
import StationListItem from '@/components/StationListItem.vue'
import GradientBackground from '@/components/GradientBackground.vue'
import RegionMap from '@/components/RegionMap.vue'
import PollutantValue from '@/components/PollutantValue.vue'
import PollutionCalendar from '@/components/charts/PollutionCalendar.vue'
import PlotlyChart from '@/components/charts/PlotlyChart.vue'

const router = useRouter()

const props = defineProps({
	id: String
})

const recommendationsType = ref("all")

const recommendationsTemplate = computed(() => {
	// Get recommendations object
	const recommendations = uaqiDescriptions[region.value?.level]?.recommendations

	// Skip if level is zero or no recommendations
	if (recommendations == null) return null

	let template = [
		{
			type: "outdoor",
			name: "На вулиці",
			icon: faTreeCity
		},
		{
			type: "indoor",
			name: "В приміщенні",
			icon: faHome
		},
		{
			type: "in_case_of_symptoms",
			name: "При загостренні",
			icon: faLungsVirus
		},
		{
			type: "additional",
			name: "Додатково",
			icon: faCircleInfo
		}
	]

	// Remove from view if no recommendations
	template = template.filter(e => (recommendations[e.type])?.[recommendationsType.value] != null)

	// Add recommendations texts
	template = template.map(e => ({ ...e, recommendations: recommendations[e.type][recommendationsType.value] }))

	return template
})

const pollutantsToHide = [ "Temperature", "Humidity", "Pressure" ]

const pollutantsFiltered = computed(() => {
	return [...region.value?.pollutants]?.filter(item => !pollutantsToHide.includes(item.type));
})

const calendarTypes = [ 
	{ type: "main", name: "UAQI" }, 
	{ type: "A", name: "Мікропил" }, 
	{ type: "B", name: "Основні гази" }, 
	{ type: "C", name: "Інші гази" }, 
	{ type: "D", name: "Радіаційний фон" } 
] 
const calendarType = ref(calendarTypes[0])

const region = ref({})

const hoveredStationId = ref(null)

const sectionRealtime = ref(null)
const sectionStatistics = ref(null)
const sectionStations = ref(null)

function goToSection(section) {
	section.scrollIntoView({ behavior: 'smooth' })
}

const dataLoaded = ref(false)

// Load region data
const fetchData = async () => {
	try {
		// const response = await axios.get(`/test_data/region${props.id}.json`)
		const response = await axios.get(`/api/v1/region?region_name=${props.id}`)
		region.value = response.data

		dataLoaded.value = true
	} catch (error) {
		console.error('Error fetching REGION: ', error)
	}
}

const stationsGraphs = ref(null)
const calendarData = ref(null)

const selectedGraph = ref({ type: "PM2.5", unit: "µg/m³" })
const dataMonthTitle = computed(() => {
	return selectedGraph.value.type + " (" + selectedGraph.value.unit + ")"
})

const dataMonth = computed(() => {
	if (stationsGraphs.value == null || stationsGraphs.value.stations.length < 1) return

	let average = {
		y: [],
		x: stationsGraphs.value.timestamps,
		marker: { color: '#bbb' },
		line: { width: 3 }, 
		name: "Середнє"
	}

	let data = []
	
	stationsGraphs.value.stations.forEach(e => {
		// FIXME: Ytor 
		if (region.value.stations.find(s => s.id == e.id && s.service == true)) {
			return
		}

		const series = e.series.find(s => 
			s.type == selectedGraph.value.type && 
			s.unit == selectedGraph.value.unit
		)

		if (series == null) return

		average.y.push(series.values)

		data.push(
			{
				x: stationsGraphs.value.timestamps,
				y: series.values,
				marker: { color: '#353540' },
				name: e.name
			}
		)
	})

	let average_y = []

	const arrayAverage = arr => {
		const filtered = arr.filter(num => num !== null && num !== undefined)
		return filtered.reduce((p, c) => p + c, 0) / filtered.length
	}

	for (let i = 0; i < average.x.length; i++)
	{
		average_y.push(arrayAverage(average.y.map(e => e[i])))
	}

	average.y = average_y

	data.push(average)

	return data
})

const fetchStats = async () => {
	try {
		const response = await axios.get(`/api/v1/graphs/average_30d_1d?region_name=${props.id}`)
		stationsGraphs.value = response.data
	} catch (error) {
		console.error('Error fetching STATS: ', error)
	}
}

const fetchCalendar = async () => {
	try {
		const response = await axios.get(`/api/v1/graphs/average_365d_1d?region_name=${props.id}`)
		calendarData.value = response.data
	} catch (error) {
		console.error('Error fetching CALENDAR: ', error)
	}
}

onMounted(async () => {
	// Load data
	await fetchData()
	await fetchStats()
	await fetchCalendar()
})
</script>

<style scoped>
.comingSoon > * {
	filter: blur(4px);

	pointer-events: none;
}

.comingSoon::before {
	content: "Стане доступно незабаром :)";
	
	position: absolute;
	width: 120px;
	transform: translateY(40px);
	left: 50%;
	margin-left: -60px;

	text-align: center;

	font-weight: bold;

	z-index: 50;

	filter: drop-shadow(0px 5px 10px #00000060)
}

.typeSelectButtons .btn {
	margin-right: 5px;
	margin-bottom: 0.2em;
}


.stationList {
	overflow-y: auto;
	padding-top: 0.3em;

	overflow-y: overlay;
}

.topControls {
	display: flex;
	justify-content: space-between;
}

.topControls > div .btn {
	background-color: #101010;
	/* font-size: medium; */
	border: 1px solid v-bind(uaqiColors[region.level]);
}

.topControls > div .btn:hover {
	background-color: v-bind(uaqiColors[region.level]);
	color: white;
	/* font-size: medium; */
}

.navigationControls .btn + .btn {
	margin-left: 0.5em;
}

.navigationControls {
	display: flex;
}

@media only screen and (max-width: 520px) {
	.navigationControls > .btn > span {
		display: none;
	}
}

.btn > svg {
	margin-bottom: -0.5px;
}

.btn > span {
	margin-left: 0.5em;
}

.textDim { 
	color: var(--color-text-dim)
}

.uaqiInfo {
	display: flex;
	flex-direction: column;
}

.pollutantsContainer {
	display: flex; 
	flex-direction: row; 
	/* flex-wrap: wrap; */

	overflow-x: auto;
}

.infoPanel {
	background-color: var(--color-paper);
	border: 1px solid var(--color-border);
	border-radius: 5px;
	padding: 1em;

	width: 100%;

	box-sizing: border-box;

	margin-bottom: 0.5em;
}

.infoPanel h3 {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
}

.infoPanel ul {
	margin: 0;
	padding-left: 1em;
}

.infoPanel li {
	margin-bottom: 0.5em;
}

@media only screen and (min-width: 660px) {
	.uaqiInfo {
		flex-direction: row;
	}

	.infoPanel {
		margin-bottom: 0px;
	}

	.infoPanel + .infoPanel {
		margin-left: 0.5em;
	}
}

.graphContainer {
	background-color: #151515;
	border-radius: 15px;
}
</style>