<!-- https://motorscript.com/share-this-component-for-vue-nuxt/ -->

<template>
	<div class="share-this">
		<!-- This is the placement code ShareThis provides. -->
		<div class="sharethis-inline-share-buttons"></div>
	</div>
</template>

<script>
export default {
	mounted() {
		const st = window.__sharethis__
		if (!st) {
			const script = document.createElement('script')
			script.src =
				'https://platform-api.sharethis.com/js/sharethis.js#property=66a386d3e1507500197b03db&product=inline-share-buttons'
			document.body.appendChild(script)
		} else if (typeof st.initialize === 'function') {
			st.href = window.location.href
			st.initialize()
		}
	}
}
</script>

<style scoped>
.share-this {
	margin-bottom: 0.5em;
}
</style>

<style>
/* Invert twitter logo */
/* TODO: light theme */
.st-btn[data-network='twitter'] {
	filter: invert(1) brightness(0.85);
}
</style>