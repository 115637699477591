<template>
	<div>
		<div class="avatar">
			<img
				v-if="User.profile?.logo != null"
				class="userLogo"
				:src="'https://reborn.eco-city.org.ua/logos/' + User.profile?.logo" 
				alt="user logo" 
				:style="{ opacity: imageLoaded ? 1 : 0 }"
				@load="imageLoaded = true"
			>
			<FontAwesomeIcon v-else style="font-size:xx-large; color: #404040;" :icon="faUser"/>
		</div>
		<Transition name="transition-height">
			<div v-if="props.showUploadInput" style="margin-top: 0.5em; overflow: hidden;">
				<input type="file" accept="image/*" @change="handleFileChange" />
			</div>
		</Transition>
	</div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from "vue";
import axios from "axios";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { User } from '@/state/User.js'
import { faUser } from "@fortawesome/free-solid-svg-icons";

const emit = defineEmits(['imageChanged'])
const props = defineProps({ showUploadInput: Boolean })

const imageLoaded = ref(false)

function handleFileChange(event) 
{
	const file = event.target.files[0];

	if (file) 
	{
		const reader = new FileReader();

		reader.onload = (e) => {
			const img = new Image()

			img.onload = () => {
				const canvas = document.createElement("canvas")
				const ctx = canvas.getContext("2d")

				// Set the size of the canvas 512x512
				canvas.width = 512
				canvas.height = 512

				// Draw images on canvas with scaling
				// ctx.drawImage(img, 0, 0, 512, 512)

				drawImageFill(img, ctx)

				// Convert the image to JPEG format
				canvas.toBlob((blob) => {
					const formData = new FormData()
					formData.append("data", blob, "profile.jpg")

					uploadImage(formData)
				}, "image/jpeg")
			}

			img.src = e.target.result
		}

		reader.readAsDataURL(file)
	}
}

function uploadImage(formData) 
{
	console.log("Sending image" , formData)

	axios
		.patch("/api/v1/cabinet/profile_image", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})
		.then(async (response) => {
			console.log("The image has been successfully sent:", response.data);
			emit('imageChanged')
			await User.fetchProfile()
			User.profile.logo = User.profile.logo + "?time=" + (new Date()).toISOString()
		})
		.catch((error) => {
			console.error("Image sending failed:", error);
			alert("Відбулась помилка завантаження(\n\nПовідомте розробників про цю неприємність.")
		});

}

// https://stackoverflow.com/a/23105310
/*
function drawImageScaled(img, ctx) {
	var canvas = ctx.canvas;
	var hRatio = canvas.width / img.width;
	var vRatio = canvas.height / img.height;
	var ratio = Math.min(hRatio, vRatio);
	var centerShift_x = (canvas.width - img.width * ratio) / 2;
	var centerShift_y = (canvas.height - img.height * ratio) / 2;
	ctx.clearRect(0, 0, canvas.width, canvas.height);
	ctx.drawImage(img, 0, 0, img.width, img.height,
		centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
}*/

function drawImageFill(img, ctx) {
	var canvas = ctx.canvas;

	// get the scale
	// it is the min of the 2 ratios
	let scaleFactor = Math.max(canvas.width / img.width, canvas.height / img.height);

	// Finding the new width and height based on the scale factor
	let newWidth = img.width * scaleFactor;
	let newHeight = img.height * scaleFactor;

	// get the top left position of the image
	// in order to center the image within the canvas
	let x = (canvas.width / 2) - (newWidth / 2);
	let y = (canvas.height / 2) - (newHeight / 2);

	// When drawing the image, we have to scale down the image
	// width and height in order to fit within the canvas
	ctx.drawImage(img, x, y, newWidth, newHeight);
}
</script>

<style scoped>
.avatar {
	width: 4em;
	height: 4em;

	border-radius: 0.5em;
	overflow: hidden;
	
	background-color: #202020;

	display: flex;
	align-items: center;
	justify-content: center;
}

.userLogo {
	width: 100%; 
	height: 100%;

	opacity: 0;

	transition: opacity 0.2s ease;
}

.transition-height-enter-active,
.transition-height-leave-active {
	transition: max-height 0.5s ease-in-out;
}

.transition-height-enter-from,
.transition-height-leave-to {
	max-height: 0;
}

.transition-height-enter-to,
.transition-height-leave-from {
	max-height: 35px;
}
</style>