import { reactive } from 'vue'

import axios from 'axios'

import { event } from 'vue-gtag'

export const User = reactive({
	profile: null,
	stations: null,
	_isAuthenticated: false,
	isAuthenticated() {
		return this._isAuthenticated
	},
	async login(email, password) {
		const user = {
			"email": email,
			"password": password
		}

		try {
			console.log("LOGIN request")
			const response = await axios.post("/api/v1/auth/signin", user)

			if (response.status == 200) {
				console.log("LOGIN SUCCESSFUL")
				this._isAuthenticated = true

				// Invoke Google Analytics event
				event('login', { method: 'Default' })
			} else {
				console.error("LOGIN FAILED")
				this._isAuthenticated = false
			}
		} catch (error) {
			console.error('Error LOGIN: ', error)
			this._isAuthenticated = false
		}

		return this.isAuthenticated()
	},
	async loginGoogle(code) {
		try {
			console.log("LOGIN request")
			const response = await axios.post("/api/v1/auth/signin/google?code=" + code)

			if (response.status == 200) {
				console.log("LOGIN SUCCESSFUL")
				this._isAuthenticated = true

				// Invoke Google Analytics event
				event('login', { method: 'Google' })
			} else {
				console.error("LOGIN FAILED")
				this._isAuthenticated = false
			}
		} catch (error) {
			console.error('Error GOOGLE LOGIN: ', error)
			this._isAuthenticated = false
		}

		return this.isAuthenticated()
	},
	async logout() {
		try {
			console.log("LOGOUT request")
			await axios.delete("/api/v1/auth/signout")

			console.log("LOGOUT SUCCESSFUL")
			this._isAuthenticated = false
			this.profile = null
			this.stations = null
		} catch (error) {
			console.error('Error LOGOUT: ', error)
		}

		return this.isAuthenticated()
	},
	async fetchProfile() {
		try {
			console.log("Loading PROFILE")
			const response = await axios.get("/api/v1/cabinet/profile")
			this.profile = response.data
			this._isAuthenticated = true
			return true
		} catch (error) {
			console.error('Failed to load PROFILE: ', error)
			return false
		}
	},
	async fetchStations() {
		try {
			console.log("Loading USER STATIONS")
			const response = await axios.get("/api/v1/cabinet/stations_list")
			this.stations = response.data
		} catch (error) {
			console.error('Failed to load USER STATIONS: ', error)
		}
	},
	async saveProfile() {
		try {
			console.log("Saving USER PROFILE edit")
			const response = await axios.patch("/api/v1/cabinet/profile", this.profile)
			return response.status == 200
		} catch (error) {
			console.error('Failed to save USER PROFILE: ', error)
			return false
		}
	}
})