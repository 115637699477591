<template>
	<div
		class="pollutantBox"
	>
		<div>{{ pollutant.type }}</div>
		<h3>{{ pollutant.value != null ? pollutant.value.toFixed(1) : "-" }}</h3>
		<div>{{ pollutant.unit }}</div>
		<small style="margin-top: 1em; text-align: center;">
			<span v-if="pollutant.stations_count">
				{{ getStationWord(pollutant.stations_count) }}
			</span>
			<span v-else-if="pollutant.sensor">
				{{ pollutant.sensor }}
			</span>
		</small>
	</div>
</template>

<script setup>
import { defineProps, computed } from 'vue'

import { getStationWord } from '@/modules/Language.js'
import { uaqiColors } from '@/modules/UAQI.js'

const props = defineProps({
	pollutant: Object
})

const levelColor = computed(() => {
	return uaqiColors[props.pollutant.level != null ? props.pollutant.level : 0]
})
</script>

<style scoped>
.pollutantBox {
	border-radius: 5px;
	background: linear-gradient(to bottom, v-bind(levelColor + "80"), #00000000);
	border: 1px solid v-bind(levelColor + "A0");

	padding: 1em 1.2em;
	margin-top: 0.2em;
	margin-bottom: 0.2em;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 2;

	min-width: 4.5em;

	user-select: none;
	width: 100%;
}

.pollutantBox + .pollutantBox {
	margin-left: 0.3em;;
}

.pollutantBox > h3 {
	margin-top: 0.5em;
	margin-bottom: 0.2em;
}
</style>