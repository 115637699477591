import { reactive } from 'vue'

import axios from 'axios'

import { event } from 'vue-gtag'

export const SelectedStation = reactive({
	station: null,
	data: null,
	graphs: { 
		average_48h_1h: null,
		average_30d_1d: null,
	},
	_cancelSource: null,
	_onChangedCallbacks: [],
	getInfo() {
		return this.station;
	},
	getData() {
		return this.data;
	},
	getGraphs() {
		return this.graphs
	},
	set(station) {
		if (station == null) {
			console.error("Station is NULL")
			return
		}

		if (this.station != null && station.id == this.station.id) {
			console.log("Station is already selected")
			return
		}

		console.log("Selected station: " + station.id)
		this.station = station

		// Invoke Google Analytics event
		event('view_station', { station_id: station.id, station_name: station.name })

		// If data is loading, drop request
		if (this._cancelSource != null) { 
			this._cancelSource.cancel('Request canceled due to user action')
		}

		// Create new cancellation source
		this._cancelSource = axios.CancelToken.source();

		this.fetchData()
		this.fetchGraph()

		// Run onChanged callback
		for (const callback of this._onChangedCallbacks) callback()
	},
	remove() {
		this.station = null
	},
	async fetchData() {
		try {
			const response = await axios.get(
				`api/v1/station?id=${this.station.id}`,
				{ cancelToken: this._cancelSource.token }
			)
			console.log("STATION loaded")
			this.data = updateData(this.data, response.data)
		} catch (error) {
			console.error("Error fetching STATION: ", error)
			this.data = null;
		}

		function updateData(oldData, newData) {
			// Data from other station or first station
			if (oldData == null || oldData.id != newData.id) {
				return newData
			}

			// No updates needed
			if (oldData.last_online == newData.last_online) {
				return oldData
			}

			// Update values
			// for (v in newData.current_values) {
				
			// 	let oldValue = oldData.current_values.find(
			// 		e => e.sensor == v.sensor && 
			// 		e.type == v.type && 
			// 		e.unit == v.unit
			// 	)

			// 	if (oldValue) {
			// 		oldValue.value = v.value
			// 	} else {

			// 	}
			// }

			// Update other data
			for (const key in oldData) {
				if (!(key in newData)) delete oldData[key]
			}

			Object.assign(oldData, newData)

			return newData
		}
	},
	async fetchGraph() {
		try {
			// const response = await axios.get(`/test_data/graph${"1"}.json`)
			const response = await axios.get(
				`/api/v1/graphs/average_48h_1h?station_id=${this.station.id}`,
				{ cancelToken: this._cancelSource.token }
			)

			this.graphs.average_48h_1h = response.data
			console.log("GRAPH loaded")
		} catch (error) {
			console.error("Error fetching GRAPH: ", error)
		}
	},
	isSelected(station) {
		if (this.station == null) return false
		
		// Compare by id
		return this.station.id == station.id
	},
	onStationChanged(callback) {
		if (typeof callback === 'function') {
			this._onChangedCallbacks.push(callback)
		} else {
			throw new Error('Callback must be a function');
		}
	}
})