import { reactive } from 'vue'

import axios from 'axios'

export const RegionsData = reactive({
	regions: null,
	updatedAt: null, // Used to check if data need to be updated
	updateInProgress: false, // Check to avoid running few updates in the same time
	get() {
		return this.regions
	},
	async getUpdated() {
		// Update data if needed
		if (this.regions == null) 
			await this.update()
		else if (this.isUpdateNeeded()) 
			this.update()

		return this.regions
	},
	isUpdateNeeded() {
		// If never received data
		if (this.updatedAt == null) return true

		// The number of milliseconds after which the data should be updated
		const updateTimeout = (30 * 1000) 

		return (Date.now() - this.updatedAt > updateTimeout)
	},
	async update() {
		if (this.updateInProgress) { 
			console.warn("Regions update already in progress")
			return;
		}

		try {
			console.log("Loading REGIONS data")
			this.updateInProgress = true
			
			// const response = await axios.get('test_data/regions.json')
			const response = await axios.get('/api/v1/regions')

			this.updatedAt = Date.now()
			this.regions = response.data
			
			console.log("REGIONS data updated")
			this.updateInProgress = false
		} catch (error) {
			console.error('Error fetching REGIONS: ', error)
		}
	}
})