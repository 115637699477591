import { reactive } from 'vue'

import axios from 'axios'

export const StationsData = reactive({
	stations: null,
	_updatedAt: null, // Used to check if data need to be updated
	_inUseOf: [ ], // Used to pause autofetch from server
	autoUpdate: false, // Is auto update set up
	_bounds: { // Filter stations by coordinates
		north: 50.7360,
		south: 50.1922,
		east: 31.0110,
		west: 30.01130,
	},
	get() {
		return this.stations
	},
	isUpdateNeeded() {
		// If never received data
		if (this._updatedAt == null) return true

		// The number of milliseconds after which the data should be updated
		const updateTimeout = (10 * 1000) 

		return (Date.now() - this._updatedAt > updateTimeout)
	},
	async update() {
		try {
			console.log("Loading STATIONS data")

			const response = await axios.get(
				"/api/v1/map" + 
				"?south=" + 43.5 + 
				"&west=" + 22.0 +
				"&north=" + 53.5 +
				"&east=" + 40.3
			)

			this.stations = response.data

			console.log("STATIONS data updated")
			this._updatedAt = Date.now()
		} catch (error) {
			console.error('Error fetching STATIONS: ', error)
		}
	},
	setInUse(nameOfService) {
		if (this._inUseOf.indexOf(nameOfService) == -1) {
			this._inUseOf.push(nameOfService)
		}
	},
	unsetInUse(nameOfService) {
		const index = this._inUseOf.indexOf(nameOfService)
		if (index != -1) {
			this._inUseOf.splice(index, 1);
		}
	},
	isInUse()
	{
		return this._inUseOf.length > 0
	},
	setupAutoUpdating() {
		console.log("AutoUpdate for STATIONS setup")
		this.autoUpdate = true
		setInterval(() => {
			if (this.isInUse()) this.update()		
		}, 10 * 1000);
	},
	setBounds(bounds) {
		this.bounds = bounds
	}
})