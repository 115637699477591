
// Colors of UAQI levels
export const uaqiColors = [
	"#808080",
	"#386270",
	"#286444",
	"#7e6626",
	"#7c2f2f",
	"#612424",
	"#4a2e5f",
];

// Bright version of UAQI colors
export const uaqiColorsBright = [
	"#808080",
	"#68CBF8",
	"#27BF88",
	"#FFC000",
	"#FF0000",
	"#C00000",
	"#7030A4",
];

export const uaqiDescriptions = [
	{
		name: "Якість повітря невідома",
		air: "ніяке",
		description: "Наразі інформація про якість повітря невідома."
	},
	{
		name: "Добра якість повітря",
		air: "добре",
		description: "Вміст у повітрі забруднюючих речовин у межах норми та не становить небезпеки.",
		// info: "Плануйте діяльність, відпочинок або інші активності на відкритому повітрі без додаткових обмежень, застережень або рекомендацій.",
		recommendations: {
			outdoor: {
				all: "Плануйте діяльність без спеціальних обмежень",
				risk: "Плануйте діяльність без спеціальних обмежень"
			},
			indoor: {
				all: "Плануйте діяльність без спеціальних обмежень",
				risk: "Плануйте діяльність без спеціальних обмежень"
			},
			in_case_of_symptoms: {
				risk: "Зверніться за консультацією до сімейного лікаря"
			},
			additional: {
				all: "Слідкуйте за оновленням інформації",
				// risk: "Слідкуйте за оновленням інформації"
			}
		}
	},
	{
		name: "Задовільна якість повітря",
		air: "задовільне",
		description: "Вміст у повітрі забруднюючих речовин у межах норми та не становить небезпеки.",
		// info: "Плануйте діяльність, відпочинок або інші активності на відкритому повітрі без додаткових обмежень, застережень або рекомендацій.",
		// consequences: "У рідких випадках у дуже невеликої кількості населення, які надзвичайно чутливі до забруднення повітря, можуть виникнути дискомфорт та рефлекторні реакції – кашель, задишка тощо. Надзвичайно чутливим до забруднення повітря людям рекомендуємо зменшити тривалі або важкі фізичні навантаження на відкритому повітрі.",
		recommendations: {
			outdoor: {
				all: "Плануйте діяльність без спеціальних обмежень",
				risk: "Плануйте діяльність без спеціальних обмежень"
			},
			indoor: {
				all: "Плануйте діяльність без спеціальних обмежень",
				risk: "Плануйте діяльність без спеціальних обмежень"
			},
			in_case_of_symptoms: {
				risk: "Уникайте активної фізичної діяльності на відкритому повітрі.\nЗверніться за консультацією до сімейного лікаря"
			},
			additional: {
				all: "Слідкуйте за оновленням інформації",
				// risk: "Слідкуйте за оновленням інформації"
			}
		}
	},
	{
		name: "Якість повітря несприятлива",
		air: "несприятливе",
		description: "Вміст у повітрі забруднюючих речовин у межах норми, проте уразливі та чутливі до забруднення категорії населення можуть мати наслідки для здоров’я при довготривалому впливі.",
		// info: "Враховуйте спеціальні обмеження, застереження та рекомендації під час планування діяльності, відпочинку або інших активностей на відкритому повітрі.\nРекомендуємо уразливим та чутливим до забруднення категоріям населення зменшити тривалі або важкі фізичні навантаження на відкритому повітрі.",
		// consequences: "Якщо ви відчуваєте дискомфорт під час дихання та прояви інших рефлекторних реакцій – рекомендуємо дотримуватися звичайних порад та плану лікування від вашого лікаря.\nЛюди хворі на астму, ХОЗЛ та респіраторні захворювання можуть відчувати посилення звичних симптомів та рефлекторних реакцій. Рекомендуємо сумлінно дотримуватися свого плану терапії хронічних захворювань та прийому ліків, які призначив ваш лікар.\nЛюди із хронічними захворюваннями серцево-судинної системи можуть відчувати додаткові та посилені рефлекторні реакції – підвищене серцебиття, задишка або незвичайна втома. У випадку довготривалого прояву цих реакцій рекомендуємо звернутися до вашого лікаря та сумлінно виконувати його настанови.",
		recommendations: {
			outdoor: {
				all: "Плануйте діяльність без спеціальних обмежень",
				risk: "Уникайте активної фізичної діяльності на відкритому повітрі \nУникайте довготривалих прогулянок"
			},
			indoor: {
				all: "Без потреби не провітрюйте приміщення",
				risk: "Без потреби не провітрюйте приміщення"
			},
			in_case_of_symptoms: {
				all: "Уникайте активної фізичної діяльності на відкритому повітрі",
				risk: "Відстежуйте зміни самопочуття \nДотримуйтеся звичних порад вашого сімейного лікаря \nЗверніться за консультацією до сімейного лікаря"
			},
			additional: {
				// all: "Слідкуйте за оновленням інформації",
				risk: "Слідкуйте за оновленням інформації \nВипийте чистої води"
			}
		}
	},
	{
		name: "Погана якість повітря",
		air: "погане",
		description: "Вміст у повітрі забруднюючих речовин становить небезпеку при довготривалому впливі.\nУсі категорії населення можуть відчувати загострення рефлекторних реакцій та мати наслідки для здоров'я при довготривалому впливі.",
		// info: "Діють спеціальні обмеження, застереження та рекомендації для тривалості діяльності, відпочинку або інших активностей на відкритому повітрі.\nУсім уразливим та чутливим до забруднення категоріям населення рекомендуємо виключити та перенести на інших час тривалі або важкі фізичні навантаження на відкритому повітрі.",
		// consequences: "Чутливі до забруднення категорії населення, які мають хронічні захворювання, можуть відчувати значне посилення звичних симптомів та рефлекторних реакцій.\nРекомендуємо сумлінно дотримуватися свого плану терапії хронічних захворювань та прийому ліків, які призначив ваш лікар.\nУ випадку довготривалого прояву рефлекторних реакцій рекомендуємо звернутися за консультацією до вашого лікаря та сумлінно виконувати його настанови.",
		recommendations: {
			outdoor: {
				all: "Уникайте активної фізичної діяльності на відкритому повітрі \nУникайте довготривалих прогулянок",
				risk: "Уникайте активної фізичної діяльності на відкритому повітрі \nУникайте довготривалих прогулянок \nУникайте спортивних та освітніх події на відкритому повітрі \nСкористайтеся засобами захисту органів дихання"
			},
			indoor: {
				all: "Закрийте вікна \nБез потреби не провітрюйте приміщення",
				risk: "Закрийте вікна \nБез потреби не провітрюйте приміщення \nВиконайте позачергове вологе прибирання"
			},
			in_case_of_symptoms: {
				all: "Відстежуйте зміни самопочуття \nЗверніться за консультацією до сімейного лікаря",
				risk: "Уникайте надмірної фізичної активності \nВідстежуйте зміни самопочуття \nЗверніться за консультацією до сімейного лікаря \nДотримуйтеся звичних порад вашого сімейного лікаря"
			},
			additional: {
				all: "Слідкуйте за оновленням інформації \nВипийте чистої води \nПоінформуйте рідних та сусідів про погіршення якості повітря",
				risk: "Слідкуйте за оновленням інформації \nВипийте чистої води \nПісля прогулянки промийте очі та ніс \nПоінформуйте рідних та сусідів про погіршення якості повітря"
			}
		}
	},
	{
		name: "Дуже погана якість повітря",
		air: "дуже погане",
		description: "Для уразливих та чутливих до забруднення категорій населення вміст у повітрі забруднюючих речовин становить небезпеку навіть при короткостроковому впливі. Усі категорії населення можуть відчувати сильне загострення рефлекторних реакцій та мати наслідки для здоров'я небезпеку навіть при короткостроковому впливі.",
		// info: "Для уразливих та чутливих до забруднення категорій населення діють спеціальні обмеження, застереження та рекомендації для перебування на відкритому повітря для будь-якої діяльності.\nРекомендуємо виключити або перенести на інший час будь-яку діяльність на відкритому повітрі.",
		// consequences: "Якщо у вас виникає рефлекторна реакція на забруднене повітря – кашель, задишка, подразнення слизових оболонок носоглотки, біль в очах тощо – рекомендуємо використовувати засоби індивідуального захисту органів дихання.\nУ випадку довготривалого прояву рефлекторних реакцій або інших відчутних наслідків для вашого здоров’я рекомендуємо звернутися за консультацією до вашого лікаря та сумлінно виконувати його настанови.",
		recommendations: {
			outdoor: {
				all: "Уникайте активної фізичної діяльності на відкритому повітрі \nУникайте довготривалих прогулянок \nУникайте спортивних та освітніх події на відкритому повітрі \nСкористайтеся засобами захисту органів дихання",
				risk: "За можливості залишайтеся у приміщенні \nУникайте активної фізичної діяльності на відкритому повітрі \nУникайте довготривалих прогулянок \nУникайте спортивних та освітніх події на відкритому повітрі \nСкористайтеся засобами захисту органів дихання"
			},
			indoor: {
				all: "Закрийте вікна \nБез крайньої потреби не провітрюйте приміщення \nВиконайте позачергове вологе прибирання \nВикористовуйте побутові очищувачі повітря",
				risk: "Закрийте вікна \nБез крайньої потреби не провітрюйте приміщення \nВиконайте позачергове вологе прибирання \nВикористовуйте побутові очищувачі повітря"
			},
			in_case_of_symptoms: {
				all: "Уникайте надмірної фізичної активності \nВідстежуйте зміни самопочуття \nЗверніться за консультацією до сімейного лікаря \nДотримуйтеся звичних порад вашого сімейного лікаря",
				risk: "Уникайте надмірної фізичної активності \nВідстежуйте зміни самопочуття \nЗверніться за консультацією до сімейного лікаря \nДотримуйтеся звичних порад вашого сімейного лікаря"
			},
			additional: {
				all: "Слідкуйте за оновленням інформації \nВипийте чистої води \nПісля прогулянки промийте очі та ніс \nПоінформуйте рідних та сусідів про погіршення якості повітря",
				risk: "Слідкуйте за оновленням інформації \nВипийте чистої води \nПісля прогулянки промийте очі та ніс \nПоінформуйте рідних та сусідів про погіршення якості повітря"
			}
		}
	},
	{
		name: "Надзвичайно погана якість повітря",
		air: "надзвичайно погане",
		description: "Вміст у повітрі забруднюючих речовин становить небезпеку навіть при короткостроковому впливі. Усі категорії населення можуть відчувати сильне загострення рефлекторних реакцій та мати наслідки для здоров'я небезпеку навіть при короткостроковому впливі.",
		// info: "Для всіх категорій населення діють спеціальні обмеження, застереження та рекомендації для перебування на відкритому повітря для будь-якої діяльності.\nРекомендуємо виключити або перенести на інший час будь-яку діяльність на відкритому повітрі.",
		// consequences: "Якщо у вас виникає рефлекторна реакція на забруднене повітря – кашель, задишка, подразнення слизових оболонок носоглотки, біль в очах тощо – рекомендуємо використовувати засоби індивідуального захисту органів дихання.\nУ випадку довготривалого прояву рефлекторних реакцій або інших відчутних наслідків для вашого здоров’я рекомендуємо звернутися за консультацією до вашого лікаря та сумлінно виконувати його настанови.",
		recommendations: {
			outdoor: {
				all: "За можливості залишайтеся у приміщенні \nСкористайтеся засобами захисту органів дихання \nСкористуйтеся захисними окулярами \nЗаборона масових заходів на відкритому повітрі \nЗаборона спортивних подій на відкритому повітрі \nЗаборона освітніх заходів на відкритому повітрі",
				risk: "За можливості залишайтеся у приміщенні \nКористуйтеся засобами захисту органів дихання \nКористуйтеся захисними окулярами \nЗаборона масових заходів на відкритому повітрі \nЗаборона спортивних подій на відкритому повітрі \nЗаборона освітніх заходів на відкритому повітрі"
			},
			indoor: {
				all: "Закрийте вікна \nБез крайньої потреби не провітрюйте приміщення \nВиконайте позачергове вологе прибирання \nВикористовуйте побутові очищувачі повітря",
				risk: "Закрийте вікна \nБез крайньої потреби не провітрюйте приміщення \nВиконайте позачергове вологе прибирання \nВикористовуйте побутові очищувачі повітря"
			},
			in_case_of_symptoms: {
				all: "Уникайте надмірної фізичної активності \nВідстежуйте зміни самопочуття \nЗверніться за консультацією до сімейного лікаря \nДотримуйтеся звичних порад вашого сімейного лікаря",
				risk: "Уникайте надмірної фізичної активності \nВідстежуйте зміни самопочуття \nЗверніться за консультацією до сімейного лікаря \nДотримуйтеся звичних порад вашого сімейного лікаря"
			},
			additional: {
				all: "Слідкуйте за оновленням інформації \nВипийте чистої води \nПісля прогулянки промийте очі та ніс \nПісля прогулянки прийміть душ \nПоінформуйте рідних та сусідів про погіршення якості повітря",
				risk: "Слідкуйте за оновленням інформації \nВипийте чистої води \nПісля прогулянки промийте очі та ніс \nПісля прогулянки прийміть душ \nПоінформуйте рідних та сусідів про погіршення якості повітря"
			}
		} 
	}
]