<template>
	<div class="loading" :class="{ hide: props.loadingStatus }" v-if="showLoadingScreen">
		<div v-if="showLoader" class="lds-dual-ring"></div>
	</div>
</template>

<script setup>
import { ref, defineProps, watch } from 'vue'

const props = defineProps({
	loadingStatus: Boolean,
	showLoader: { 
		type: Boolean,
		default: false,
		required: false
	}
})

const showLoadingScreen = ref(true)

watch(props, (a) => {
	if (a.loadingStatus == true) {
		setTimeout(() => {
			showLoadingScreen.value = false
		}, 500);
	}
})
</script>

<style scoped>
.hide {
	opacity: 0 !important;
}

.loading {
	box-sizing: border-box;

	position: fixed;

	/* top: 0px; */
	/* left: 0px; */
	
	width: 100%;
	height: 100%;

	background-color: #101010;

	/* Must be less than navbar */
	z-index: 90;

	/* To shift behind navbar */
	margin: -1em 0 -1em;

	overflow: hidden;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	opacity: 1;

	transition: opacity 0.5s ease-in-out;
}

/* https://loading.io/css/ */
.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
}

.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>