<template>
	<div class="contentContainer">
		<LoadingScreen :loadingStatus="dataLoaded" />
		<GradientBackground :level="1" />
		<div class="content">
			<br/>
			<h1>Статистика по областях</h1>
			<span>Тут представлений список областей та деяких міст. Що б отримати детальну статистику, натисніть на місто чи область, що вас зацікавила.</span>
			
			<br/><br/><br/>

			<div class="searchContainer">
				<input 
					type="text" 
					v-model="searchText"
					placeholder="Пошук по назві..."
					class="text-box"
					style="flex-grow: 1;"
				>
				<div style="margin-left: 0.5em;">
					<button 
						class="btn" 
						style="height: 100%; margin-right: 0.5em;"
						@click="() => { sortType = 'name'; searchText = '' }"
					>
						<FontAwesomeIcon :icon="faArrowUpAZ" />
						<span>За назвою</span>
					</button>
					<button 
						class="btn" 
						style="height: 100%"
						@click="() => { sortType = 'level'; searchText = '' }"
					>
						<FontAwesomeIcon :icon="faArrowUpWideShort" />
						<span>За забрудненням</span>
					</button>
				</div>
			</div>

			<br/>
			
			<div id="regionsList">
				<RegionStatsItem 
					v-for="r in regionsList"
					:key=r.name
					:region=r
					style="margin-bottom: 1em;"
					@click="router.push({ name: 'region', params: { id: r.name } })"
				/>
			</div>
			<div v-if="regionsList.length == 0" style="width: 100%; display: flex; justify-content: center;">
				<div>
					<br><br>
					Щось тут нічого нема...
					<br><br>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useFuse } from '@vueuse/integrations/useFuse'

import RegionStatsItem from '@/components/RegionStatsItem.vue';
import GradientBackground from '@/components/GradientBackground.vue'
import LoadingScreen from '@/components/LoadingScreen.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowUpAZ } from '@fortawesome/free-solid-svg-icons';
// import { faArrowUpZA } from '@fortawesome/free-solid-svg-icons';
import { faArrowUpWideShort } from '@fortawesome/free-solid-svg-icons';
// import { faArrowUpShortWide } from '@fortawesome/free-solid-svg-icons';

import { RegionsData } from '@/state/RegionsData.js'
import { uaqiColors } from '@/modules/UAQI';

const dataLoaded = ref(false)

const router = useRouter()

const searchText = ref('')

const fuseOptions = {
	// isCaseSensitive: false,
	// includeScore: false,
	// shouldSort: true,
	// includeMatches: false,
	// findAllMatches: false,
	// minMatchCharLength: 1,
	// location: 0,
	// threshold: 0.6,
	// distance: 100,
	// useExtendedSearch: false,
	// ignoreLocation: false,
	// ignoreFieldNorm: false,
	// fieldNormWeight: 1,
	keys: [
		{ name: "name", weight: 2 },
		{ name: "name_qwerty", weight: 1 }
	]
};

const sortType = ref("level")
const sortReverse = ref(false)

function ukrainianToQwerty(text) {
	const qwerty = "qwertyuiop[]asdfghjkl;'zcvbnm,./'"
	const ukr =    "йцукенгшщзхїфівапролджєячсмитьбю."

	let result = "";

	for (const c of text) {
		if (ukr.includes(c)) {
			result += qwerty[ukr.indexOf(c)]
		} else {
			result += c
		}
	}

	return result
}

const regionsList = computed(() => {
	let list = RegionsData.get()

	if (list == null) return []

	list = list.map(e => ({ ...e, name_qwerty: ukrainianToQwerty(e.name) }))

	if (searchText.value != '') 
	{
		const { results: searchResults } = useFuse(searchText, list, { fuseOptions: fuseOptions })

		list = searchResults.value.map(e => e.item)
	} 
	else 
	{
		list = list.filter(e => e.online_stations > 0)

		list.sort((a, b)  => {
			if (sortType.value == "level") {
				return a.level - b.level
			} else {
				let nameA = a.name.toLowerCase();
				let nameB = b.name.toLowerCase();

				if (nameA > nameB) return -1
				else if (nameA < nameB) return 1
				else return 0
			}
		})

		if (!sortReverse.value) list.reverse()
	}

	return list
})

onMounted(() => {
	if (RegionsData.isUpdateNeeded()) {
		RegionsData.update()
		
		watch(RegionsData, () => {
			dataLoaded.value = RegionsData.get() != null
		})
	} else {
		dataLoaded.value = true
	}
})
</script>

<style scoped>
.btn {
	background-color: #101010;
	/* font-size: medium; */
	border: 1px solid v-bind(uaqiColors[1]);

	/* border-radius: 7px; */
}

@media only screen and (max-width: 520px) {
	.btn > span {
		display: none;
	}
}

.text-box {
	background-color: #101010;
	/* font-size: medium; */
	border: 1px solid v-bind(uaqiColors[1]);

	/* border-radius: 7px; */
	
	margin: 0px;
	width: auto;
}

.text-box:focus {
	background-color: #151515;
}

.searchContainer {
	display: flex; 
	flex-direction: row;
}
</style>