<template>
	<div class="contentContainer">
		<div v-if="resetSend == false" class="content">
			<h1>Відновлення паролю</h1> 
			<p>
				Після надсилання запиту на відновлення паролю, вам прийде лист
				на вказану електронну пошту з інструкціями.
			</p>
			<input 
				v-model="email" 
				type="email" 
				placeholder="Введіть ваш e-mail" 
				:class="{ invalid: email.length > 0 && !isEmailValid(email) }"
				class="text-box"
			>
			<br/>

			<div style="margin-top: 0.5em;">
				<button 
					class="btn" 
					@click="resetClick"
				>
					Відновити пароль
				</button>
			</div>
		</div>
		<div v-if="resetSend == true" class="content">
			<h1>Відновлення паролю</h1> 
			<p>
				Запит було надіслано на вашу електронну пошту. 
				Обов'язково перевірте теку зі спамом! 
			</p>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'

import { isEmailValid } from '@/modules/DataValidation'

const email = ref("")

const resetSend = ref(false)

async function resetClick()
{
	if (!isEmailValid(email.value)) {
		window.alert("Перевірте правильність введених даних")
		return
	}

	try {
		const response = await axios.post(`/api/v1/auth/password-recovery?email=${email.value}`)
		if (response.status == 200) {
			resetSend.value = true
		}
	} catch (error) {
		console.error('Error: ', error)
		window.alert("Помилка. Перевірте правильність введених даних.")
	}
}
</script>

<style scoped>
.content {
	max-width: 24em;
}

.btn, .text-box {
	font-size: medium;
	/* border-radius: 8px; */
}

.text-box.invalid {
	background-color: #7c2f2f;
}
</style>