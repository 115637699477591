<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { onClickOutside } from '@vueuse/core'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

// https://medium.com/@romanvinnick/vue3-composition-api-creating-a-reusable-modal-component-with-outside-click-handling-43e830d289c1

const props = defineProps({
	isOpen: Boolean,
});

const emit = defineEmits(["modal-close"]);

const target = ref(null)
onClickOutside(target, () => emit('modal-close'))
</script>

<template>
	<div>
		<Transition>
			<div v-if="props.isOpen" class="modal-mask">
				<div class="modal-wrapper">
					<div class="modal-container" ref="target">
						<div class="modal-header">
							<h2>
								<slot name="header">HEADER</slot>
							</h2>
							<button 
								class="btn closeBtn" 
								@click.stop="emit('modal-close')"
							>
								<FontAwesomeIcon :icon="faXmark" />
							</button>
						</div>
						<div class="modal-body">
							<slot name="content"></slot>
						</div>
						<!-- <div class="modal-footer">
							<slot name="footer">
								<div>
									<button @click.stop="emit('modal-close')">Submit</button>
								</div>
							</slot>
						</div> -->
					</div>
				</div>
			</div>
		</Transition>
	</div>
</template>

<style scoped>
.closeBtn {
	width: 2.5em; 
	height: 2.5em; 
	
	border-radius: 50%; 
	
	display: flex; 
	justify-content: center; 
	align-items: center; 
	
	margin-left: 1em;
}

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);

	padding: 1em;

	box-sizing: border-box;
}

.modal-container {
	box-sizing: border-box;

	width: 100%;
	max-width: 32em;
	
	margin: 150px auto;
	padding: 20px 30px;

	background-color: var(--color-bg);
	border-radius: 2px;
	box-shadow: 0 2px 25px #00000020;

	border: 1px solid var(--color-border);

	border-radius: 1em;
}

.modal-header {
	display: flex;
	justify-content: space-between; 
	align-items: center;
}

.v-enter-active,
.v-leave-active {
	transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
	filter: blur(3px);
	transform: translateY(-10px);
}
</style>