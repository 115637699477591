/**
 * Expand bounds 
 * 
 * @param {Object} api Google Maps API (mapRef.value.api / new google.maps)
 * @param {Object} bounds Bounds to expand 
 * @param {Number} increasePercentage Increase percent 
 * @returns {Object} Expanded bounds object
 */
export function expandBounds(api, bounds, increasePercentage = 1.1) {
	const pointNorthEast = bounds.getNorthEast();
	const pointSouthWest = bounds.getSouthWest();

	var latAdjustment = (pointNorthEast.lat() - pointSouthWest.lat()) * (increasePercentage - 1);
	var lngAdjustment = (pointNorthEast.lng() - pointSouthWest.lng()) * (increasePercentage - 1);
	
	var newPointNorthEast = new api.LatLng(pointNorthEast.lat() + latAdjustment, pointNorthEast.lng() + lngAdjustment);
	var newPointSouthWest = new api.LatLng(pointSouthWest.lat() - latAdjustment, pointSouthWest.lng() - lngAdjustment);

	let newBounds = new api.LatLngBounds()
	newBounds.extend(newPointNorthEast)
	newBounds.extend(newPointSouthWest)

	return newBounds
}

/**
 * Check if station is in bounds 
 * 
 * @param {Object} api Google Maps API (mapRef.value.api / new google.maps)
 * @param {Object} map Map Object (mapRef.value.map / google.maps.Map) 
 * @param {Object} station Station object
 * @returns {Boolean} True if station in specified bounds
 */
export function isStationsInMapBounds(api, map, station) {
	let bounds = map?.getBounds()

	// Map not loaded
	if (bounds == null) return true

	// Expand to not clip
	bounds = expandBounds(api, bounds, 1.1)

	return bounds.contains(
		{ lat: station.latitude, lng: station.longitude }
	)
}


// Is map animation running
var isMapAnimation = false

/**
 * Set map zoom to Ukraine
 * 
 * @param {Object} api Google Maps API (mapRef.value.api / new google.maps)
 * @param {Object} map Map Object (mapRef.value.map / google.maps.Map) 
 */
export function zoomToUkraine(api, map) {
	console.log("Zoom to Ukraine")

	// Cancel animation if already zooming
	if (isMapAnimation != false) return 

    // Coordinates of Ukraine bounds
    const bounds = {
		// North offset to fit map under search bar
        north: ( 52.379528 + 0.5), // Northern latitude
        south: 44.390913, // South latitude
        east: 40.228727,  // Eastern longitude
        west: 22.128711   // Western longitude
    };

    // Create an object LatLngBounds for Ukraine's borders
    const ukraineBounds = new api.LatLngBounds(
        new api.LatLng(bounds.south, bounds.west), // Southwestern corner
        new api.LatLng(bounds.north, bounds.east)  // Northeast corner
    );

	// Disable user zoom control and set flag
	isMapAnimation = true
	map.setOptions({ gestureHandling: "none" })

	// Using timeout as it won't work in any other way
	setTimeout(() => {
		// Pan and zoom the map to the borders of Ukraine
		map.panToBounds(ukraineBounds);
		map.fitBounds(ukraineBounds);
	}, 100)

	// Waiting to the end of animation
	setTimeout(() => {
		isMapAnimation = false
		map.setOptions({ gestureHandling: "auto" })
	}, 1000)
}