export function isSerialNumberValid(serial_number) {
	const re = /^(?:\d{7,8}|[A-Fa-f0-9]{12})$/
	return re.test(serial_number)
}

export function isLatValid(l) {
	return l != null && l > 43.5 && l < 53.5
}

export function isLonValid(l) {
	return l != null && l > 22.2 && l < 40.3
}

export function isAltValid(a) {
	return a != null && a > 0.5 && a < 100
}

export function isStationNameValid(n) {
	return n != null && n.length > 0 && n.length <= 64
}

export function isUserNameValid(name) {
	return name != null && name.length > 2 && name.length <= 64
}

export function isUrlValid(url) {
	//eslint-disable-next-line
	const re = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/
	return url != null && url.length > 0 && re.test(url)
}

export function isPhoneValid(phone) {
	const re = /^(\+?38\s?)?\(?0[5-9][0-9]\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}$/
	return phone != null && phone.length > 0 && re.test(phone)
}

export function isStationValid(station) {
	if (station == null) return false 

	return (
		isLatValid(station.latitude) &&
		isLonValid(station.longitude) &&
		isAltValid(station.installation_altitude) &&
		isStationNameValid(station.name)
	)
}

export function isEmailValid(email) {
	//eslint-disable-next-line
	const re = /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/
	return email != null && re.test(email)
}