<template>
	<div v-html="blogHtml" style="width: 100%;"></div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

import axios from 'axios'

const blogHtml = ref(null)

const fetchBlog = async () => {
	try {
		const response = await axios.get("/changelog/changelog.html")

		blogHtml.value = response.data
	} catch (error) {
		console.error('Error fetching blogposts:', error)
	}
}

onMounted(()=>{
	fetchBlog()
})
</script>

<style>
.clPost {
	background-color: var(--color-paper);

	border-radius: 0.5em;

	border: 1px solid var(--color-border);

	margin-bottom: 1em;

	width: 100%;

	box-sizing: border-box;
}

.clPost h3 {
	margin-top: 0.1em;
	margin-bottom: 0.5em;
}

.clPost p {
	font-size: smaller;

	margin-top: 0.6em;
	margin-bottom: 0.2em;
}

.clContainer {
	position: relative;
}

.clContent {
	position: relative;

	padding: 1em;
	padding-left: 8em;
}

.clImage {
	/* height: 10em; */
	height: 100%;

	overflow: hidden;
	border-radius: 0.5em;

	aspect-ratio: 4/3;

	position: relative;

	pointer-events: none;
	position: absolute;
	z-index: 0;

	opacity: 0.8;
}

.clImage::before{
	content: '';

	background: 
		linear-gradient(to left, var(--color-paper), #00000000),
		linear-gradient(to bottom, #00000000, #19aaee41);

	width: 100%;
	height: 100%;

	display: block;
	position: absolute;
}

.clImage > img {
	height: 100%;
}
</style>