<template>
	<GradientBackground :level="1" />
	<div class="contentContainer">
		<div class="content">
			<h1>Реєстрація станції</h1>
			<p>
				Реєстрація станції відбувається в декілька кроків.
				Ваша станція повинна бути підключена до мережі та надіслати дані на сервер як мінімум один раз.
			</p>
			<p>
				Після реєстрації ваша станція стане видимою на мапі та доступною у вашому кабінеті користувача.
				Ви зможете також приховати станцію з мапи та змінити інші налаштування.
			</p>
			<br /><br />
			<div>
				<!-- https://devrecipes.net/step-progres-bar-with-pure-css/ -->
				<div class="stepper-wrapper"> 
					<div 
						v-for="step in stepperSteps"
						:key="step"
						class="stepper-item"
						:class="{ active: stepperSteps[currentStep] == step, completed: stepperSteps.indexOf(step) < currentStep }"
					>
						<div class="step-counter">
							<FontAwesomeIcon :icon="step.icon"/>
						</div>
						<div class="step-name">{{ step.name }}</div>
					</div>
				</div>
			</div>
			<br /><br />
			<div v-show="currentStep == 0">
				<p>
					Для початку введіть серійний номер вашої станції:
				</p>
				<input 
					class="text-box" 
					type="text" 
					placeholder="XXXXXXXXXXXX"
					v-model="newStation.serial_number"
					:class="{ invalid: newStation.serial_number.length > 0 && !isSerialNumberValid(newStation.serial_number) }"
				>
			</div>

			<div v-show="currentStep == 1">
				<div class="coordinates">
					<small>Широта</small><br/>
					<input 
						class="text-box"
						type="number" 
						step=".000001" 
						v-model="newStation.latitude"
						placeholder="Довгота"
						style="margin-bottom: 0.5em;"
						:class="{ invalid: !isLatValid(newStation.latitude) }"
					>
					<small>Довгота</small><br/>
					<input
						class="text-box"
						type="number" 
						step=".000001" 
						v-model="newStation.longitude"
						placeholder="Широта"
						style="margin-bottom: 0.5em;"
						:class="{ invalid: !isLonValid(newStation.longitude) }"
					>
				</div>
				<small>Висота встановлення (метрів)</small><br/>
				<input
					class="text-box"
					type="number" 
					step=".1"
					v-model="newStation.installation_altitude"
					placeholder="Висота встановлення"
					@input="userTouchedAltInput = true"
					:class="{ invalid: userTouchedAltInput && !isAltValid(newStation.installation_altitude) }"
				>
				<div class="locationSelectorContainer" style="margin-top: 0.5em;">
					<LocationSelector 
						@selected="onLocationSelected"
						:location="stationLocation"
					/>
				</div>
			</div>

			<div v-show="currentStep == 2">
				<small>Назва станції</small><br/>
				<input 
					class="text-box"
					type="text" 
					v-model="newStation.name"
					placeholder="Введіть назву"
					style="margin-bottom: 0.5em;"
					:class="{ invalid: newStation.name.length > 0 && !isStationNameValid(newStation.name) }"
				>
				<small>Тип встановлення</small><br/>
				<div style="margin-top: 0.2em;">
					<button 
						class="btn" 
						:class="{ active: newStation.type == 'outdoor' }"
						style="margin-right: 0.25em;"
						@click="newStation.type = 'outdoor'"
					>
						Зовнішня
						<FontAwesomeIcon :icon="faTreeCity"/>
					</button>
					<button 
						class="btn" 
						:class="{ active: newStation.type == 'indoor' }"
						style="margin-right: 0.25em;"
						@click="newStation.type = 'indoor'"
					>
						Внутрішня
						<FontAwesomeIcon :icon="faHouseChimney"/>
					</button>
					<button 
						class="btn" 
						:class="{ active: newStation.type == 'portable' }"
						@click="newStation.type = 'portable'"
					>
						Портативна
						<FontAwesomeIcon :icon="faBriefcase"/>
					</button>
				</div>
			</div>

			<div v-show="currentStep == 3">
				<p>
					Перевірте правильність введених даних. 
					Якщо вас все влаштовує, то залишилось лише одне натискання кнопки!
				</p>
				<div class="finalStation">
					<FontAwesomeIcon :icon="getStationIcon(newStation)"/>
					<div>
						<div style="font-weight: bold;">{{ newStation.name }}</div>
						<small>Координати: {{ newStation.latitude }} / {{ newStation.longitude }}</small><br/>
						<small>Висота встановлення: {{ newStation.installation_altitude }}м</small>
					</div>
				</div>
			</div>

			<div style="padding-top: 1em; display: flex; justify-content: space-between; align-items: end;">
				<div>
					<button 
						class="btn" 
						@click="currentStep--"
						v-show="currentStep > 0"
					>
						<FontAwesomeIcon :icon="faCaretLeft"/>
						Назад
					</button>
				</div>
				<button 
					class="btn" 
					@click="nextClick()"
				>
					Далі
					<FontAwesomeIcon :icon="faCaretRight"/>
				</button>
			</div>
			<!-- <br/><br/> -->
			<!-- <input type="number" name="" id="" v-model="currentStep"> -->
		</div>
	</div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useGeolocation } from '@vueuse/core'
import { useRouter } from 'vue-router'

import axios from 'axios'

import { 
	faClipboardCheck, 
	faLink, 
	faMapLocationDot, 
	faPenToSquare,
	faCaretRight, 
	faCaretLeft
} from '@fortawesome/free-solid-svg-icons';
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons'
import { faTreeCity } from '@fortawesome/free-solid-svg-icons'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { getStationIcon } from '@/modules/StationIcon.js'

import LocationSelector from '@/components/LocationSelector.vue';
import GradientBackground from '@/components/GradientBackground.vue'

import { 
	isSerialNumberValid, 
	isLatValid, 
	isLonValid, 
	isAltValid, 
	isStationNameValid 
} from '@/modules/DataValidation.js';

const router = useRouter()

const { coords } = useGeolocation()

const currentStep = ref(0)

const stepperSteps = ref([
	{
		name: "Станція",
		icon: faLink
	},
	{
		name: "Локація",
		icon: faMapLocationDot
	},
	{
		name: "Дані",
		icon: faPenToSquare
	},
	{
		name: "Додавання",
		icon: faClipboardCheck
	}
])

const newStation = ref({
	name: "",
	latitude: 50.45,
	longitude: 30.52,
	type: "outdoor",
	hidden: false,
	share: true,
	serial_number: "",
	installation_altitude: null
})

const geolocationUnwatch = watch(coords, () => {
	if (coords.value != null && isLatValid(coords.value.latitude) && isLonValid(coords.value.longitude)) {
		console.log("GET LOCATION", coords.value)
		newStation.value.latitude = coords.value.latitude
		newStation.value.longitude = coords.value.longitude

		geolocationUnwatch()
	}
})

const userTouchedAltInput = ref(false)

const stationLocation = computed(() => {
	return { lat: parseFloat(newStation.value.latitude), lng: parseFloat(newStation.value.longitude) }
})

async function nextClick()
{
	const steps = [
		stepSerial,
		stepLocation,
		stepInfo,
		stepConfirmation
	]

	if (await steps[currentStep.value]()) {
		currentStep.value++
	}
}

async function stepSerial() {
	if (isSerialNumberValid(newStation.value.serial_number) == false) {
		window.alert("Перевірте правильність введених даних")
		return false
	}

	try {
		const response = await axios.get(`/api/v1/cabinet/check_serial_number?serial=${newStation.value.serial_number}`)
		if (response.status == 200) {
			console.log("SERIAL IS VALID")
			return true
		} else {
			window.alert("Відбулась невідома помилка( \nПовідомте розробникам про дане непорозуміння.")
			return false
		}
	} catch (error) {
		console.error('Error: ', error)
		if (error.response.status == 404) {
			window.alert("Станція не була знайдена. \nМожливо серійний номер введений неправильно, або станція ще не надсилала дані на сервер.")
		} else if (error.response.status == 403) {
			window.alert("Ви зробили забагато запитів на сервер. \nНаступний запит ви зможете зробити через годину.")
		} else {
			window.alert("Відбулась невідома помилка( \nПовідомте розробникам про дане непорозуміння.")
		}
			
		return false
	}
}

function stepLocation() {
	if (
		!isLatValid(newStation.value.latitude) || 
		!isLonValid(newStation.value.longitude) || 
		!isAltValid(newStation.value.installation_altitude)
	)
	{
		window.alert("Перевірте правильність введених даних")
		userTouchedAltInput.value = true
		return false
	}

	return true
}

function stepInfo() {
	if (!isStationNameValid(newStation.value.name)) {
		window.alert("Перевірте правильність введених даних")
		return false
	}

	return true
}

async function stepConfirmation() {
	if (await addStation()) {
		window.alert("Станцію успішно додано!")
		router.push({name:"cabinet"})
		return true
	} else {
		window.alert("Відбулась невідома помилка( \nПовідомте розробникам про дане непорозуміння.")
		return false
	}
}

function onLocationSelected(location) {
	newStation.value.latitude = location.lat
	newStation.value.longitude = location.lng
}

async function addStation() {
	try {
		console.log("adding STATION")

		const response = await axios.put("/api/v1/cabinet/add_station", newStation.value)

		if (response.status == 200) {
			console.log("STATIONS added")
			return true
		} else {
			return false
		}
	} catch (error) {
		console.error('Error adding STATION: ', error)
		return false
	}
}

</script>

<style scoped>

/* HIDE INPUT NUMBER UP/DOWN */
/* Chrome, Safari, Edge, Opera */
.coordinates input::-webkit-outer-spin-button,
.coordinates input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.coordinates input[type=number] {
  -moz-appearance: textfield;
}

.content {
	max-width: 24em;
}

.text-box.invalid {
	background-color: #7c2f2f;
}

.finalStation {
	background-color: #202020; 
	border-radius: 0.5em; 
	padding: 1em; 
	display: flex; 
	align-items: center;
}

.finalStation svg {
	margin-right: 0.75em;
	font-size: larger
}

.stepper-wrapper {
	display: flex;
	justify-content: space-between;
}

.locationSelectorContainer {
	width: 100%; 
	height: 20em;

	border-radius: 0.5em;

	overflow: hidden;
}

.stepper-item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;

	@media (max-width: 768px) {
		/* font-size: 12px; */
	}
}

.stepper-item::before {
	position: absolute;
	content: "";
	border-bottom: 6px solid #202020;
	width: 100%;
	top: 17px;
	left: -50%;
	z-index: 2;
}

.stepper-item::after {
	position: absolute;
	content: "";
	border-bottom: 6px solid #202020;
	width: 100%;
	top: 17px;
	left: 50%;
	z-index: 2;
}

.stepper-item .step-counter {
	position: relative;
	z-index: 5;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #202020;
	margin-bottom: 6px;
}

.step-counter {
	font-family: var(--font-h);
	font-weight: 900;
	transition: all ease-in-out 0.2s;
}

.stepper-item.active .step-counter {
	background-color: #386270;
	border-radius: 25%;
}

.stepper-item.active {
	font-weight: bold;
}

.stepper-item.completed .step-counter {
	background-color: #243a41;
}

.stepper-item.completed::after {
	position: absolute;
	content: "";
	border-bottom: 6px solid #243a41;
	width: 100%;
	top: 17px;
	left: 50%;
	z-index: 3;
}

.stepper-item:first-child::before {
	content: none;
}

.stepper-item:last-child::after {
	content: none;
}
</style>