
// Change the end of a word based on a numerator
export function getStationWord(number) {
    let word = "станцій";

    if (number % 100 >= 11 && number % 100 <= 14) {
        word = "станцій";
    } else {
        switch (number % 10) {
            case 1:
                word = "станція";
                break;
            case 2:
            case 3:
            case 4:
                word = "станції";
                break;
            default:
                word = "станцій";
        }
    }

    return `${number} ${word}`;
}