<template>
	<div style="width: 100%; height: 100%; position: relative;" >
		<GoogleMap 
			ref="mapRef"
			api-key="AIzaSyBgVH0fE8NBe3dgXJzCU9v3be8Q0IcOvQM" 
			style="width: 100%; height: 100%" 
			:zoom=defaultMapZoom
			:minZoom=5
			:isFractionalZoomEnabled=true
			:disableDefaultUi=true 
			:clickableIcons=false 
			:backgroundColor="'#212121'"
			:gestureHandling="disabled ? 'none' : 'auto'"
			:styles="darkStyle"
		>
		</GoogleMap>
		<div class="centerMarker">
			<FontAwesomeIcon :icon="faLocationDot"/>
		</div>
		<!-- <div class="centerMarkerDebug"></div> -->
	</div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from 'vue'

import { GoogleMap } from 'vue3-google-map'
import { darkStyle } from '@/modules/GoogleMapStyle.js'

import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const emit = defineEmits(['selected'])
const props = defineProps({ location: Object, disabled: Boolean })

// const defaultCenter = { lat: 50.449399, lng: 30.523335 }
const defaultMapZoom = 13

const mapRef = ref(null)

const setByUser = ref(null);

// Watch for "ready" then do something with the API or map instance
watch(() => mapRef.value?.ready, (ready) => {
	if (!ready) return

	console.log("GOOGLE MAP is ready")

	// let api = mapRef.value.api
	let map = mapRef.value.map

	// Pan to location from input
	watch(setByUser, () => {
		map.panTo(setByUser.value)
	}, { immediate: true })
	
	// Add a listener to the event of "moving the map"
	map.addListener('idle', function () {
		// Do nothing if component disabled
		if (props.disabled) return

		// Get the center of the map
		const center = map.getCenter()

		const lat = center.lat().toFixed(6)
        const lng = center.lng().toFixed(6)

		if (lat == 0 && lng == 0) return

		// If location from input then do not call an event
		if (setByUser.value?.lat != lat && setByUser.value?.lng != lng) {
			emit('selected', { lat: lat, lng: lng })
		}
	});
})

watch(props, () => {
	if (props.location != null) {
		setByUser.value = props.location
	}
}, { immediate: true })

</script>

<style scoped>
.centerMarker {
	position: absolute;
	top: 50%;
	left: 50%;
	/* border: 1px solid #ffffff50; */

	transform: translate(-50%, -85%);

	font-size: 32px;
	pointer-events: none;
	
	filter: drop-shadow(0 -2px 10px #00000060);
}

.centerMarkerDebug {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 4px;
	height: 4px;
	background-color: red;
	border-radius: 50%;
	transform: translate(-50%, -50%);
}
</style>