<template>
	<div class="welcomeContainer">
		<div class="panel">
			<h2>Вітаємо на оновленому EcoCity ✨</h2>

			<div style="margin-bottom: 2em;">
				<p>
					Представляємо вам альфа-версію нашого нового вебсайту. Він все ще 
					перебуває в розробці тому деяких функцій тут може не вистачати.
				</p>
				<p>
					Ви все ще можете перейти на нашу 
					<a href="https://eco-city.org.ua">класичну версію сайту</a>.
				</p>
			</div>
			
			<h3>Останні оновлення</h3>
			<div style="overflow-y: scroll; height: 15em; border-radius: 0.25em;">
				<ChangelogBlog/>
			</div>

			<br/><br/>

			<div style="width:100%; display:flex; justify-content: center;">
				<button 
					class="btn"
					style="
						font-size: medium;
						border: 1px solid #252525;
					"
					@click="emit('closeClicked')"
				>
					Погнали дивитись
					<FontAwesomeIcon :icon="faPlay" style="margin-left: 0.3em;"/>
				</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineEmits } from 'vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import ChangelogBlog from './ChangelogBlog.vue';

const emit = defineEmits(['closeClicked'])
</script>

<style scoped>
.welcomeContainer {
	position: absolute;
	top: 0px;
	left: 0px; 

	width: 100%;
	height: 100%;

	z-index: 100;

	background-color: #000000AA;

	display: flex;
	align-items: center;
	justify-content: center;

	padding: 1em;

	box-sizing: border-box;
}

.panel {
	background-color: var(--color-bg);
	border-radius: 10px;

	border: 1px solid var(--color-border);

	padding: 1.5em;

	max-width: 30em;
}
</style>