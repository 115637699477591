<template>
	<GradientBackground :level="1" />
	<div class="contentContainer">
		<LoadingScreen :loadingStatus="dataLoaded" />
		<div class="content">
			<div class="topControls">
				<div>
					<button class="btn" @click="router.push({ name: 'home' })">
						<FontAwesomeIcon :icon="faAngleLeft" />
						<span>Назад</span>
					</button>
				</div>
				<div class="navigationControls">
					<button class="btn" @click="logoutClick">
						Вихід
						<FontAwesomeIcon :icon="faRightToBracket"/>
					</button>
				</div>
			</div>
			<br />
			<h1>Кабінет</h1>
			<p>
				Вітаємо у кабінеті! Тут ви можете керувати вашими станціями, 
				реєструвати нові, та завантажувати архіви даних.
			</p>
			<br/>
			<div class="row">
				<div class="col-xs-12 col-sm-6">
					<h2>Налаштування акаунту</h2>
					<small>{{ User.profile?.email }}</small>
					<br/><br/>
					<div>
						<button v-if="!editProfile" class="btn" @click="editProfile = true">
							Редагувати 
							<FontAwesomeIcon :icon="faPen"/>
						</button>
						<button v-else class="btn" @click="saveProfileClick()">
							Зберегти 
							<FontAwesomeIcon :icon="faFloppyDisk"/>
						</button>
					</div>
					<br/>
					<ImageUpload :show-upload-input="editProfile"/>
					<br/>
					<div v-if="User.profile != null" class="accountData">
						<small>Ім'я / Назва організації: </small>
						<input 
							:disabled="!editProfile" 
							type="text" 
							class="text-box" 
							:class="{ invalid: !nameValid }"
							v-model="User.profile.full_name"
						>

						<small>Вебсайт: </small>
						<input 
							:disabled="!editProfile" 
							type="url" 
							class="text-box" 
							:class="{ invalid: !urlValid }"
							v-model="User.profile.url"
						>

						<small>Телефон: </small>
						<input 
							:disabled="!editProfile" 
							type="tel" 
							class="text-box" 
							:class="{ invalid: !phoneValid }"
							v-model="User.profile.phone"
						>
					</div>
				</div>
				<div class="col-xs-12 col-sm-6">
					<h2>Ваші станції</h2>
					<small>У вашій власності {{ getStationWord(User.stations != null ? User.stations.length : 0) }}</small>
					<br/><br/>
					<button class="btn" @click="router.push({name:'add-station'})">
						Додати
						<FontAwesomeIcon :icon="faPlus"/>
					</button>
					<br/><br/>
					<StationListItem 
						v-for="s in User.stations"
						:key="s.id"
						:station="s"
						style="margin-bottom: 0.5em;"
						@click="router.push({ name: 'cabinet-station', params: { id: s.id } })"
					/>
					<div
						v-if="User.stations?.length == 0"
					>
						Ви поки не зареєстрували ні однієї станції(
					</div>
				</div>
			</div>
			
			<br/><br/>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

import StationListItem from '@/components/StationListItem.vue'
import GradientBackground from '@/components/GradientBackground.vue'
import LoadingScreen from '@/components/LoadingScreen.vue'
import ImageUpload from '@/components/AvatarUpload.vue'

import { getStationWord } from '@/modules/Language.js'
import { uaqiColors } from '@/modules/UAQI.js'
import { User } from '@/state/User.js'
import { isPhoneValid, isUrlValid, isUserNameValid } from '@/modules/DataValidation.js'

const router = useRouter()

const editProfile = ref(false)

const dataLoaded = ref(false)

async function logoutClick() {
	await User.logout()
	router.push({ name: "login" })
}

async function saveProfileClick() {
	if (nameValid.value && urlValid.value && phoneValid.value) {
		const saved = await User.saveProfile()

		if (saved == false) {
			window.alert("Дані не збережено! Перевірте правильність написання та відповідність формату.")
		}

		User.fetchProfile()

		editProfile.value = false
	}
	else
	{
		window.alert("Перевірте правильність введених даних")
	}
}

const nameValid = computed(() => {
	const name = User.profile.full_name
	return isUserNameValid(name)
})

const urlValid = computed(() => {
	const url = User.profile.url
	return isUrlValid(url) ||  url == null || url.length > 0
})

const phoneValid = computed(() => {
	const phone = User.profile.phone
	return isPhoneValid(phone)
})

onMounted(() => {
	User.fetchProfile()
	User.fetchStations()
})

watch(User, (u) => {
	// Detect data loaded
	if (u.profile != null) dataLoaded.value = true;

	// Sort stations
	if (u.stations != null) {
		u.stations.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
		// u.stations.sort((a, b) => a.last_online.trim().localeCompare(b.last_online.trim()));
	}
})

onBeforeRouteLeave(() => {
	if (editProfile.value) {
		const answer = window.confirm(
			'Схоже ви не зберегли Впевнені що хочете покинути сторінку?'
		)
		// cancel the navigation and stay on the same page
		if (!answer) return false
	}
})
</script>

<style scoped>
h2 {
	margin-bottom: 0.2em;
}

.topControls {
	display: flex;
	justify-content: space-between;
}

.topControls > div .btn {
	background-color: #101010;
	/* font-size: medium; */
	border: 1px solid v-bind(uaqiColors[1]);
}

.topControls > div .btn:hover {
	background-color: v-bind(uaqiColors[1]);
	color: white;
	/* font-size: medium; */
}

.navigationControls .btn + .btn {
	margin-left: 0.5em;
}

.navigationControls {
	display: flex;
}

@media only screen and (max-width: 520px) {
	.navigationControls > .btn > span {
		display: none;
	}
}

.btn > svg {
	margin-bottom: -0.5px;
}

.btn > span {
	margin-left: 0.5em;
}

.accountData .text-box:disabled {
	background-color: #00000000;
	color: var(--color-text);
	padding-left: 0px;
	font-weight: 700;

	padding-top: 0.1em;
	padding-bottom: 0.1em;
}

.accountData .text-box {
	font-size: medium;
	transition: all 0.5s ease-in-out;

	font-weight: normal;

	margin-bottom: 1em;
}

.accountData .invalid {
	background-color: #7c2f2f;
}

.noteBox {
	border-radius: 0.3em;
	background-color: var(--color-paper);

	padding: 1em;

	display: flex;
	align-items: center;
}
</style>