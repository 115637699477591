<template>
	<div 
		v-if="x != null && y != null"
		class="regionBox" 
		:style="{ top: `${y - 60}px`, left: `${x + 20}px` }"
	>
		<div 
			style="font-weight: bold;" 
			:style="{ color: uaqiColorsBright[region.level]}"
		>
			{{ region.name }}
		</div>
		<div>{{ uaqiDescriptions[region.level].name }}</div>
	</div>
</template>

<script setup>
import { defineProps } from 'vue';
import { uaqiColorsBright, uaqiDescriptions } from '@/modules/UAQI.js'

defineProps({
	region: Object
})

import { useMouse } from '@vueuse/core'

const { x, y } = useMouse({ touch: false })
</script>

<style scoped>
.regionBox {
	position: absolute;
	
	background-color: var(--color-bg);
	border-radius: 10px;
	padding: 5px;

	/* This ensures the element doesn't interfere with mouse events */
	pointer-events: none;

	/* Add transition for smooth movement */
	transition: top 0.2s ease, left 0.2s ease;
}
</style>