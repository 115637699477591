<template>
	<div class="diagramContainer">
		<div class="topContainer">
			<small>{{ onHoverText }}</small>
			<small>{{ onHoverTime }}</small>
		</div>
		<div class="plotContainer">
			<div
				v-for="v in values"
				:key="v"

				@mouseover="onBarHover(v)"
			>
				<div :style="{ 
					height: scaleByData(v.value, values.map(e => e.value)) + '%', 
					backgroundColor: uaqiColorsBright[valueLevel(v.value)] 
				}"></div>
				<div v-if="v.time.getHours() == 0 || v.time.getHours() == 12" class="line"></div>
			</div>
		</div>

		<div class="lablesContainer">
			<small
				v-for="l in timestampLables"
				:key="l"
				style="white-space: pre-wrap;"
			>
				{{ formatTimestamp(l) }}
			</small>
		</div>
	</div>
</template>

<script setup>
import { defineProps, computed, ref } from 'vue'

import { uaqiColorsBright } from '@/modules/UAQI.js';

import { format } from "date-fns";

const props = defineProps({
	data: Object,
	type: String,
	sensor: String,
	unit: String
})

function onBarHover(v) {
	const valueText = v.value == null ? "-" : v.value.toFixed(2)

	onHoverText.value = valueText + ' ' + props.unit
	onHoverTime.value = format(v.time, "dd.MM HH:mm")
}

const series = computed(() => {
	if (props.data?.series == null) return { }

	const s = props.data.series.find(s => s.sensor == props.sensor && s.type == props.type)

	return s
})

const values = computed(() => {
	if (series.value?.values == null) return []

	let result = [ ]

	for (let i = 0; i < series.value.values.length; i++) {
		let time = props.data.timestamps[i]
		time = new Date(time)

		result.push({
			value: series.value.values[i],
			time: time
		})
	}

	return result
})

const count = computed(() => {
	if (props.data.series == null) return 0;
	return props.data.series.reduce((max, series) => {
        return Math.max(max, series.values.length);
    }, 0);
})

function scale(value, inMin, inMax, outMin, outMax) {
    return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}

function scaleByData(value, vector) {
	if (value == null) return 0;
	const vectorClear = vector.filter(e => e != null)
	return scale(value, Math.min(...vectorClear), Math.max(...vectorClear), 10, 90)
}

function valueLevel(value) {
	const levels = [ 0 ].concat(series.value.levels)

	// Even if there are no levels, there will be two elements in this array after concat()
	if (levels == null || levels.length <= 2) return 0

	// Find level
	for (let i = 0; i < levels.length; i++) {
        if (value < levels[i]) {
            return i
        }
    }
    return levels.length
}

const timestampLables = computed(() => {
	const timestamps = props.data.timestamps

    const first = timestamps[0];
    const last = timestamps[timestamps.length - 1];
    const interval = Math.floor((timestamps.length - 1) / 3);

    const second = timestamps[interval];
    const third = timestamps[2 * interval];

    return [first, second, third, last];
})

function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${day}.${month}\n${hours}:${minutes}`;
}

const onHoverText = ref("Графік за останні 48 годин")
const onHoverTime = ref("")
</script>

<style scoped>
.diagramContainer {
	width: 100%;
	aspect-ratio: 6 / 4;

	display: flex;
	flex-direction: column;

	padding: 0.5em;
	box-sizing: border-box;
}

.topContainer {
	margin: 0.3em;

	display: flex;
	justify-content: space-between;
}

.plotContainer {
	background-color: #101010;

	flex-grow: 2;

	border-radius: 10px;

	/*  padding: 0.5em; */

	display: flex;
	flex-direction: row;
	align-items: flex-end;

	padding: 0.5em;
}

/* Bar */
.plotContainer > div {
	width: v-bind(100 / count + "%");
	box-sizing: border-box;

	padding: 1px;

	height: 100%;

	display: flex;
	flex-direction: column-reverse;
	align-items: center;

	filter: brightness(0.8) saturate(2);
	
	transition: all 0.3s ease-in-out;
}

.plotContainer > div:hover {
	filter: brightness(1) saturate(2);
}

.plotContainer > div:hover > div:first-child {
	transform: scaleX(1.4);
}

.plotContainer > div > div {
	border-radius: 10px;
	width: 100%;
}

.line {
	width: 0px !important;
	border-radius: 0px !important;
	border: none;
	border-right: 1px dashed #505050;

	flex-grow: 2;
}

.lablesContainer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;

	margin-top: 0.5em;
}


.appear-enter-active,
.appear-leave-active {
	transition: max-width 1s ease;
	max-width: 100%;
}

.appear-enter-from,
.appear-leave-to {
	max-width: 0em;
}
</style>