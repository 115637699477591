<template>
	<div class="regionItem">
		<div class="regionName">
			<div>
				<h2 style="margin: 0px;">{{ region.name.replace(" область", "") }}</h2>
				<small>Всього встановлено {{ getStationWord(region.installed_stations) }}</small>
			</div>
		</div>
		<div class="regionPollutantsList">
			<div
				v-for="v in region.pollutants"
				:key="v.type"
				class="regionPollutant"
			>
				<span>{{ v.type }}</span>
				<h3>{{ v.value != null ? v.value.toFixed(1) : "-" }}</h3>
				<small>{{ v.unit }}</small>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { uaqiColors } from '@/modules/UAQI.js'

import { getStationWord } from '@/modules/Language.js'

const props = defineProps({
	region: {
		type: Object,
		required: true
	}
})

const levelColor = computed(() => {
	return uaqiColors[props.region.level]
})

</script>

<style scoped>
.regionItem {
	display: flex;
	flex-direction: row;

	background-color: var(--color-bg);

	padding: 15px;

	border-radius: 15px;

	overflow: hidden;

	user-select: none;
	cursor: pointer;

	border: 1px solid v-bind(levelColor);

	/* box-shadow: inset 0 0px 30px v-bin + 20); */

	transition: background-color 0.3s ease-in-out,
				box-shadow 0.5s ease-in-out,
				translate 0.3s ease-out;
}

.regionItem:hover {
	background-color: v-bind(levelColor + 20);
	box-shadow: 0 2px 10px var(--color-bg);
	translate: 0 -5px;
}

.regionName {
	width: 100%;

	margin: -15px;
	padding: 20px;
	padding-left: 20px;

	display: flex;
	align-items: center;

	background: linear-gradient(to right, v-bind(levelColor + "60"), #00000000);
}

/* Make block vertical */
@media only screen and (max-width: 700px) {
	.regionItem {
		flex-direction: column;
	}

	.regionName {
		background: linear-gradient(v-bind(levelColor + "60"), #00000000);
	}
}

.regionPollutantsList {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.regionPollutant {
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 3.5em;

	padding: 10px 10px;
}

@media only screen and (max-width: 520px) {
	.regionPollutant:last-child {
		/* display: none !important; */
	}	
}

.regionPollutant > h3 {
	margin: 0.2em;
}
</style>