<template>
	<div class="contentContainer">
		<div class="content">
			<h1>Реєстрація кабінету</h1> 
			<p>Кабінет дозволяє вам керувати власними станціями моніторингу</p>

			<input 
				v-model="user.email" 
				type="text" 
				placeholder="Введіть ваш e-mail" 
				class="text-box"
				:class="{ invalid: user.email.length > 0 && !isEmailValid(user.email) }"
			>
			<input 
				v-model="user.password" 
				type="password" 
				placeholder="Введіть ваш пароль" 
				class="text-box"
			>
			
			<div style="padding-top: 0.5em;">
				<button 
					class="btn" 
					@click="enterButtonClick()"
					ref="enterButton"
				>
					Зареєструватись
					<FontAwesomeIcon :icon="faRightToBracket"/>
				</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'

import { isEmailValid } from '@/modules/DataValidation'

const user = ref({
	email: "",
	full_name: "",
	ip_address: "",
	password: "",
	phone: ""
})

</script>

<style scoped>
.content {
	max-width: 24em;
}

.btn, .text-box {
	font-size: medium;
	/* border-radius: 8px; */
}

.text-box.invalid {
	background-color: #7c2f2f;
}

</style>