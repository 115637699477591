<template>
	<div class="stationValue" @click="graphOpened = !graphOpened">
		<div class="valueTexts">
			<div class="valueName">
				<div style="font-weight: bold;">{{ value.local_type }}</div>
				<small>{{ value.type }}</small>
			</div>
			<div class="valueMeasurement">
				<span 
					style="font-weight: bold; margin-right: 0.2em;"
				>
					{{ value.value == null ? "-" : value.value.toFixed(2) }}
				</span> 
				<span>{{ value.local_unit }}</span>
			</div>
		</div>
		<Transition name="appear">
			<div class="graph" v-if="graphOpened">
				<ValueGraph 
					:data="SelectedStation.graphs.average_48h_1h" 
					:type="value.type"
					:sensor="value.sensor"
					:unit="value.unit"
				/>
			</div>
		</Transition>
	</div>
</template>

<script setup>

import { defineProps, computed, ref } from 'vue';

import { uaqiColors } from '@/modules/UAQI.js'
import { SelectedStation } from '@/state/SelectedStation';

import ValueGraph from '@/components/charts/ValueGraph.vue'

const levelColor = computed(() => {
	return uaqiColors[props.value.level]
})

const props = defineProps({
	value: {
		type: Object,
		required: true
	}
})

const graphOpened = ref(false)
</script>

<style scoped>

.stationValue {
	background-color: var(--color-bg);
	border-radius: 10px;
	padding: 0.5em;

	display: flex;
	flex-direction: column;

	border: 1px solid v-bind(levelColor + "A0");

	user-select: none;
	cursor: pointer;

	overflow: hidden;

	-webkit-tap-highlight-color: transparent;

	transition: background-color 0.3s ease-in-out,
				box-shadow 0.5s ease-in-out,
				translate 0.3s ease-out;
}

.stationValue:hover {
	background-color: v-bind(levelColor + "40");
	box-shadow: 0 2px 10px var(--color-bg);
	translate: 0 -2px;
}

.valueTexts {
	display: flex;
}

.valueName {
	flex-grow: 2;
	background: linear-gradient(to right, v-bind(levelColor + "80"), #00000000);

	margin: -0.5em;
	padding: 0.5em;
}

.valueMeasurement {
	display: flex;
	justify-content: right;
	align-items: center;
}

.graph {
	overflow: hidden;
}

.appear-enter-active,
.appear-leave-active {
	transition: max-height 0.5s ease;
	/* FIXME */
	max-height: 20em;
}

.appear-enter-from,
.appear-leave-to {
	max-height: 0em;
}

</style>