<template>
	<div class="contentContainer">
		<div v-if="resetSend == false" class="content">
			<h1>Зміна паролю</h1> 
			<p>
				Для того, щоб оновити пароль, введіть ваш новий пароль.
			</p>
			<p style="color: red" v-if="inputError != null">
				{{ inputError }}
			</p>
			<input v-model="password" type="password" placeholder="Введіть новий пароль" class="text-box">
			<input v-model="password_check" type="password" placeholder="Введіть його ще раз" class="text-box">
			<br/><br/>
			<button 
				class="btn" 
				:disabled="inputError != null" 
				@click="resetClick"
			>
				Змінити пароль
			</button>
		</div>
		<div v-if="resetSend == true" class="content">
			<h1>Зміна паролю</h1> 
			<p>
				Пароль успішно змінено
			</p>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router';
import axios from 'axios'

const password = ref("")
const password_check = ref("")

const resetSend = ref(false)

const inputError = computed(() => {
	if (password.value.length < 8) 
		return "Пароль занадто короткий";
	else if (password.value != password_check.value) 
		return "Паролі не співпадають";
	else 
		return null;
})

async function resetClick()
{
	if (password.value.length < 8 || password.value != password_check.value)
	{
		window.alert("НЕПРАВИЛЬНО ВВЕДЕНИЙ ПАРОЛЬ")
		return	
	}

	try {
		const response = await axios.patch(`/api/v1/auth/reset-password?new_password=${password.value}&token=${token}`)
		if (response.status == 200) {
			resetSend.value = true
		}
	} catch (error) {
		console.error('Error: ', error)
		window.alert("Помилка. Перевірте правильність введених даних.")
	}
}

const route = useRoute();
const token = route.query.token;

onMounted(() => {
	if (token == null) {
		window.alert("ПОМИЛКА. НЕПРАВИЛЬНЕ ПОСИЛАННЯ.")
	}
})
</script>

<style scoped>
.content {
	max-width: 24em;
}
</style>