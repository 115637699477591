<template>
	<GoogleMap 
		ref="mapRef"
		api-key="AIzaSyBgVH0fE8NBe3dgXJzCU9v3be8Q0IcOvQM" 
		style="width: 100%; height: 100%" 
		:disableDefaultUi=true 
		:clickableIcons=false 
		:backgroundColor="'#212121'"
		gestureHandling="none"
		:styles="darkStyle"
	>
		<Marker 
			v-for="station in props.stations"
			:key="station.id"
			:options="{ 
				position: { lat: station.latitude, lng: station.longitude },
				icon: generateMarkerIcon(station.level, station.id == props.hoveredStationId),
				zIndex: station.id == props.hoveredStationId ? 10 : station.level
			}"
		/>
	</GoogleMap>
</template>

<script setup>

import { ref, watch, defineProps } from 'vue'

import { Marker, GoogleMap } from 'vue3-google-map'
import { darkStyle } from '@/modules/GoogleMapStyle.js'

import { uaqiColors } from '@/modules/UAQI';

const props = defineProps({
	stations: Array,
	hoveredStationId: String
})

const mapRef = ref(null)

function calculateBounds(markers) {
    let bounds = new mapRef.value.api.LatLngBounds();

    markers.forEach(marker => {
        let latLng = new mapRef.value.api.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
        bounds.extend(latLng);
    });

    return bounds;
}

// Watch for "ready" then do something with the API or map instance
watch(() => mapRef.value?.ready, (ready) => {
	if (!ready) return

	console.log("GOOGLE MAP is ready")

	mapRef.value.map.fitBounds(calculateBounds(props.stations))
})

function generateMarkerIcon(level, hover) {
	const stroke = (level != 0 ? 4 : (hover ? 3 : 0))

	const svgMarker = {
		path: "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z",
		fillColor: level != 0 ? uaqiColors[level] : "#606060",
		fillOpacity: 1,
		strokeWeight: stroke,
		strokeColor: hover ? "#aaaaaa" : "#191919",
		rotation: 0,
		scale: level != 0 ? 0.035 : 0.015,
		// anchor: new mapRef.value.api.Point(200, 200)
	}

	return svgMarker
}

</script>

<style scoped>

</style>