
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons'
import { faTreeCity } from '@fortawesome/free-solid-svg-icons'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'



export function getStationIcon(station) {

	const typeToIcon = {
		"indoor": faHouseChimney,
		"outdoor": faTreeCity,
		"service": faWrench,
		"portable": faBriefcase
	}

	if (station == null) 
		return faCircle

	if (station.service == true) 
		return typeToIcon["service"]

	return typeToIcon[station.type]
}
