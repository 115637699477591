import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'
import vue3GoogleLogin from 'vue3-google-login'

import "./assets/main.css"
import "./assets/flexboxgrid.min.css"

import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://reborn.eco-city.org.ua/'

createApp(App)
	.use(router)
	.use(VueGtag, { appName: "RebornOne", config: { id: "G-DLKJT4GH4N" } }, router)
	.use(vue3GoogleLogin, { 
		clientId: '346250455480-851jk6b9r5dtv3tc0fj1qnc8a98he7c4.apps.googleusercontent.com'})
	.mount('#app')
