<template>
	<div>
		<div class="container">
			<Transition>
				<img v-if="imageSrc" style="width: 100%;" :src="imageSrc" alt="Зображення для поширення" />
			</Transition>
			<div v-if="false" style="height: 100%; display: flex; justify-content: center; align-items: center;"> 
				<FontAwesomeIcon :icon="faCircle" style="font-size: xx-large;" beat-fade />
			</div>
		</div>
		<br/>
		<a :href="imageSrc" :download="imageDownloadName">
			<button class="btn" style="margin-right: 0.5em;">
				Завантажити&nbsp;
				<FontAwesomeIcon :icon="faDownload" />
			</button>
		</a>
		<button class="btn" @click="shareImageClick">
			Поширити&nbsp;
			<FontAwesomeIcon :icon="faShare" />
		</button>
	</div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from "vue";

import { uaqiColorsBright, uaqiColors, uaqiDescriptions } from "@/modules/UAQI";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCircle, faDownload, faShare } from '@fortawesome/free-solid-svg-icons'

const svgUrl = "/StationShareHorizontalDark3-2.svg";

const props = defineProps({
	stationData: Object
})

const imageSrc = ref(null);
const imageDownloadName = computed(() => {
	const stationName = removeIllegalCharacters(props.stationData.name)
	const stationTime = removeIllegalCharacters(props.stationData.last_online)
	return `EcoCity ${stationName} ${stationTime}.png`
})

async function shareImageClick() {
	// FIXME
	const imageBlob = await fetch(imageSrc.value).then(res => res.blob())
	const file = new File([imageBlob], imageDownloadName.value, imageBlob)

	console.warn(file)

	const data = {
		url: window.location.href,
		text: `${props.stationData.name} (Станція EcoCity)`,
		title: `${props.stationData.name}`,
		files: [file]
	}

	console.log(navigator.canShare(data))

	navigator.share(data)
}

function removeIllegalCharacters(str) {
	return str != null ? str.replace(/[/\\?%*:|"<>]/g, '-') : str
}

function getValuesForSvg(stationValues) {
	const filteredData = stationValues.filter(item => item.level > 0);
	const data = filteredData.slice(0, filteredData.length >= 5 ? 5 : filteredData.length);
	
	const types = data.map(item => item.type);
	const values = data.map(item => item.value.toFixed(1));
	const units = data.map(item => item.local_unit);
	const levels = data.map(item => item.level);

	return { types, values, units, levels }
}

function processSvg(svg) {
	let result = svg

	const levelToText = [ "-", "I", "II", "III", "IV", "V", "VI", "VII" ]

	result = result.replace("{{StationName}}", props.stationData.name)
	result = result.replace("{{StationAddress}}", props.stationData.address)
	result = result.replaceAll("{{ColorLevel}}", uaqiColors[props.stationData.uaqi.main])

	result = result.replace("{{AirIs}}", uaqiDescriptions[props.stationData.uaqi.main].air)
	result = result.replace("{{UaqiMain}}", levelToText[props.stationData.uaqi.main])

	result = result.replace("{{ColorUaqiPm}}", uaqiColors[props.stationData.uaqi.categories.A])
	result = result.replace("{{ColorUaqiMainGases}}", uaqiColors[props.stationData.uaqi.categories.B])
	result = result.replace("{{ColorUaqiOtherGases}}", uaqiColors[props.stationData.uaqi.categories.C])
	result = result.replace("{{ColorUaqiRadiation}}", uaqiColors[props.stationData.uaqi.categories.D])

	const data = getValuesForSvg(props.stationData.current_values)

	for (let i = 0; i < data.types.length; i++) {
		result = result.replace(`{{Type${i}}}`, data.types[i])
		result = result.replace(`{{Value${i}}}`, data.values[i])
		result = result.replace(`{{Unit${i}}}`, data.units[i])
		result = result.replaceAll(`{{Level${i}Color}}`, uaqiColorsBright[data.levels[i]])
	}

	// Replace with empty fields if values less than 5
	result = result.replaceAll(/{{(Unit|Type|Value)\d}}/g, "")

	result = result.replace("{{Timestamp}}", props.stationData.last_online)

	return result
}

async function loadSVG() {
	try {
		const response = await fetch(svgUrl);
		if (!response.ok) {
			throw new Error(`Failed to download SVG: ${response.statusText}`);
		}

		const templateSvg = await response.text();
		const finalSvg = processSvg(templateSvg)

		imageSrc.value = await convertSvgToPng(finalSvg, 1200, 630)
	} catch (error) {
		console.error("Loading error SVG:", error);
	}
}

async function convertSvgToPng(svgData, width, height) {
	// Create a URL for SVG data
	const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
	const url = URL.createObjectURL(svgBlob);

	// Download SVG into the image
	const img = new Image();
	img.src = url;

	// Waiting for the image to load
	await new Promise((resolve) => (img.onload = resolve));

	// Create canvas for PNG rendering
	const canvas = document.createElement('canvas');
	canvas.width = width;
	canvas.height = height;
	const ctx = canvas.getContext('2d');

	ctx.drawImage(img, 0, 0, width, height);
	URL.revokeObjectURL(url);

	return new Promise((resolve) => {
		canvas.toBlob((blob) => {
			const pngUrl = URL.createObjectURL(blob);
			resolve(pngUrl);
		}, 'image/png');
	});
}

onMounted(() => {
	loadSVG()
})
</script>

<style scoped>
.container {
	width: 100%;
	aspect-ratio: 1200 / 630;

	overflow: hidden;

	border-radius: 0.5em;

	background-color: var(--color-paper);
}

img {
	width: 100%;
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>