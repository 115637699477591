<template>
	<div class="contentContainer">
		<LoadingScreen :loadingStatus="dataLoaded" />
		<GradientBackground :level="stationData?.uaqi?.main"/>
		<div class="content">
			<div class="topControls">
				<div>
					<button class="btn" @click="router.go(-1)">
						<FontAwesomeIcon :icon="faAngleLeft" />
						<span>Назад</span>
					</button>
				</div>
				<div class="navigationControls">
					<button class="btn" @click="goToSection(sectionRealtime)">
						<FontAwesomeIcon :icon="faCircleDot" :fade="true" />
						<span>Поточні дані</span>
					</button>
					<button class="btn" @click="goToSection(sectionStatistics)">
						<FontAwesomeIcon :icon="faChartLine" />
						<span>Статистика</span>
					</button>
					<button class="btn" @click="goToSection(sectionArchives)">
						<FontAwesomeIcon :icon="faFileZipper" />
						<span>Архіви</span>
					</button>
				</div>
			</div>
			<br />
			
			<h1>
				<FontAwesomeIcon :icon="getStationIcon(stationData)" />
				{{ stationData?.name }}
			</h1>
			<p>Станція моніторингу якості повітря</p>
			<br/>
			
			<div ref="sectionRealtime">
				<h2>Поточні дані</h2>

				<div v-if="showRealtime">
					<p>Значення забрудників на момент {{ stationData?.last_online }}</p>
					
					<div class="pollutantsContainer">
						<PollutantValue 
							v-for="p in pollutantsFiltered"
							:key="p"
							:pollutant=p
						/>
					</div>
				</div>
				<div v-else>
					<p>Станція востаннє була онлайн занадто давно(</p>
				</div>
				<br/>
			</div>
			
			<div ref="sectionStatistics">
				<h2>Статистика</h2>

				<div v-if="average_30d_1d != null">
					<p>Статистика на основі даних з {{ dataLimits?.start }} до {{ dataLimits?.end }}</p>
					
					<div class="typeSelectButtons">
						<button
							class="btn outline"
							v-for="s in average_30d_1d?.series"
							:key="s"
							:class="{ active: monthSeries == s}"
							@click="monthSeries = s; dataMonthTitle = s.type + ' (' + s.unit + ')'"
						>
							{{ s.type }}
						</button>
					</div>
					<br/>
					<div class="row">
						<div class="col-xs-12 col-sm-6">
							<h3 style="margin-bottom: 0.3em;">Усереднені дані по днях</h3>
							<div style="margin-bottom: 1.2em;">за останні 30 днів</div>
							<PlotlyChart 
								:data=dataMonth 
								:layout="{ yaxis: { title: dataMonthTitle, overlaying: 'y2' }, yaxis2: { side: 'right', fixedrange: true, visible: false } }"
								:title=dataMonthTitle 
								:fixedRange=true
								style="height: 13em;"
							/>
						</div>
						<div class="col-xs-12 col-sm-6">
							<h3 style="margin-bottom: 0.3em;">По днях тижня</h3>
							<div style="margin-bottom: 1.2em;">за останні 30 днів</div>
							<PlotlyChart 
								:data=weekdayData 
								:layout="{ yaxis: { title: dataMonthTitle }}"
								:title=dataMonthTitle 
								:fixedRange=true
								style="height: 13em;"
							/>
						</div>
					</div>
					<br/>
					<div class="row">
						<div class="col-xs-12 col-sm-6">
							<h3 style="margin-bottom: 0.3em;">По часу доби</h3>
							<div style="margin-bottom: 1.2em;">за останні 7 днів</div>
							<PlotlyChart 
								:data=daytimeData 
								:layout="{ yaxis: { title: dataMonthTitle }}"
								:title=dataMonthTitle 
								:fixedRange=true
								style="height: 13em;"
							/>
						</div>
						<div class="col-xs-12 col-sm-6">
							<h3 style="margin-bottom: 0.3em;">Гістограма значень</h3>
							<div style="margin-bottom: 1.2em;">за останні 7 днів</div>
							<PlotlyChart 
								:data=histogramData 
								:layout="{ yaxis: { title: 'Кількість значень' }, /*xaxis: { title: dataMonthTitle }*/}"
								:title=dataMonthTitle 
								:fixedRange=true
								style="height: 13em;"
							/>
						</div>
					</div>
				</div>
				<div v-else>
					<p>
						Станція востаннє була онлайн настільки давно, що в нас 
						нема даних навіть за останній місяць(
					</p>
				</div>
				
				<br/><br/>
			</div>

			<div ref="sectionArchives">
				<h2>Завантаження архівів даних</h2>
				<p>
					Колись тут буде можливість завантажити дані зі станції)
				</p>
			</div>
			
			<br/><br/>
			<br/><br/>
		</div>
	</div>
</template>

<script setup>
import { ref, defineProps, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'

import LoadingScreen from '@/components/LoadingScreen.vue'
import GradientBackground from '@/components/GradientBackground.vue'
import PlotlyChart from '@/components/charts/PlotlyChart.vue'
import PollutantValue from '@/components/PollutantValue.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faCircleDot } from '@fortawesome/free-solid-svg-icons'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { faFileZipper } from '@fortawesome/free-solid-svg-icons'

import { uaqiColors } from '@/modules/UAQI.js'
import { getStationIcon } from '@/modules/StationIcon'

import axios from 'axios'
import { differenceInHours } from 'date-fns';

const router = useRouter()

const props = defineProps({ id: String })

const sectionRealtime = ref(null)
const sectionStatistics = ref(null)
const sectionArchives = ref(null)

function goToSection(section) {
	section.scrollIntoView({ behavior: 'smooth' })
}

const pollutantsToHide = [ "Temperature", "Humidity", "Pressure" ]

const pollutantsFiltered = computed(() => {
	if (stationData.value == null) return []
	return [...stationData.value.current_values]?.filter(e => !pollutantsToHide.includes(e.type));
})

const showRealtime = computed(() => {
	if (stationData.value == null) return false

	const diffInHours = differenceInHours(stationData.value.last_online, new Date());

	return diffInHours > -3
})


const graph1Loaded = ref(false)
const graph2Loaded = ref(false)

const dataLoaded = computed(() => {
	return graph1Loaded.value && graph2Loaded.value
})

const average_48h_1h = ref(null)
const average_30d_1d = ref(null)
const average_7d_20min = ref(null)
const stationData = ref(null)

const dataLimits = ref(null)

const fetchAvg48 = async () => {
	try {
		const response = await axios.get(`/api/v1/graphs/average_48h_1h?station_id=${props.id}`)

		if (response.data?.series?.length == 0) {
			console.warn("average_48h_1h data empty")
		} else {
			average_48h_1h.value = response.data
		}
	} catch (error) {
		console.error('Error fetching GRAPH: ', error)
	}

	graph1Loaded.value = true
}

const fetchAvg30 = async () => {
	try {
		const response = await axios.get(`/api/v1/graphs/average_30d_1d?station_id=${props.id}`)

		if (response.data?.series?.length == 0) {
			console.warn("average_30d_1d data empty")
		} else {
			average_30d_1d.value = response.data
	
			monthSeries.value = average_30d_1d.value.series[0]
			dataMonthTitle.value = average_30d_1d.value.series[0].type + " (" + average_30d_1d.value.series[0].unit + ")"
	
			dataLimits.value = { 
				start: average_30d_1d.value.timestamps.at(0).substring(0, 10),
				end: average_30d_1d.value.timestamps.at(-1).substring(0, 10)
			}
		}
	} catch (error) {
		console.error('Error fetching GRAPH: ', error)
	}

	graph2Loaded.value = true
}

const fetchAvg20 = async () => {
	try {
		const response = await axios.get(`/api/v1/graphs/average_7d_20min?station_id=${props.id}`)

		if (response.data?.series?.length == 0) {
			console.warn("average_7d_20min data empty")
		} else {
			average_7d_20min.value = response.data
		}
	} catch (error) {
		console.error('Error fetching GRAPH: ', error)
	}
}

const fetchStation = async () => {
	try {
		const response = await axios.get(`/api/v1/station?id=${props.id}`)
		stationData.value = response.data
	} catch (error) {
		console.error('Error fetching STATION: ', error)
	}
}

// function getColor(v, levels) {
	
// 	let resultLevel = 0

// 	for (let level in levels) {
// 		if (v < level) break
// 		else resultLevel++
// 	}
	
// 	return uaqiColors[resultLevel]
// }

const monthSeries = ref(null)
const dataMonthTitle = ref("")

const dataMonth = computed(() => {
	if (average_30d_1d.value == null) return null

	const values = monthSeries.value.values
	const counts = monthSeries.value.counts

	const data = [
	{
			x: average_30d_1d.value.timestamps,
			y: counts,
			marker: { color: '#151515' },
			type: "bar",
			yaxis: 'y2',
			name: "Кількість",
			hoverinfo: "none"
		},
		{
			x: average_30d_1d.value.timestamps,
			y: values.map(v => v?.toFixed(2)),
			marker: { color: '#bbb' },
			name: monthSeries.value.type
		}
	]

	return data
})

const weekdayData = computed(() => {
	if (average_30d_1d.value == null) return null

	const timestamps = average_30d_1d.value.timestamps
	const data = monthSeries.value

	// Feature to get the day of the week
	function getDayOfWeek(dateString) {
		const date = new Date(dateString);

		// TODO: Remake to day number
		return date.toLocaleString('uk-UA', { weekday: 'long' });
	}

	// Create an object to save data on the days of the week
	const weekData = {};

	timestamps.forEach((timestamp, index) => {
		if (data.values[index] !== null) {
			const dayOfWeek = getDayOfWeek(timestamp);
			if (!weekData[dayOfWeek]) {
				weekData[dayOfWeek] = [];
			}
			weekData[dayOfWeek].push(data.values[index]);
		}
	});

	// The order of days of the week
	const orderedDays = ["понеділок", "вівторок", "середа", "четвер", "пʼятниця", "субота", "неділя"];
	const daysShortNames = {"понеділок": "пн", "вівторок": "вт", "середа": "ср", "четвер": "чт", "пʼятниця": "пт", "субота": "сб", "неділя": "нд"};

	// Form data for boxing
	const plotData = orderedDays.map(day => ({
		y: weekData[day] || [],
		type: 'box',
		marker: { color: '#bbb' },
		name: daysShortNames[day]
	}));

	return plotData
})

const daytimeData = computed(() => {
	if (average_7d_20min.value == null) return null

	const data = average_7d_20min.value

	const timestamps = data.timestamps.map(timestamp => new Date(timestamp));
	const hours = timestamps.map(date => date.getHours());

	const series = data.series.find(s => 
		s.type == monthSeries.value.type &&
		s.unit == monthSeries.value.unit &&
		s.sensor == monthSeries.value.sensor
	)

	if (series == null) {
		console.error("Series not found")
		return []
	}

	const values = series.values;

	// Grouping values by hour 
	const hourGroups = {};
	hours.forEach((hour, index) => {
		if (!hourGroups[hour]) {
			hourGroups[hour] = [];
		}
		hourGroups[hour].push(values[index]);
	});

	// Data preparation for Plotly
	const plotData = Object.keys(hourGroups).map(hour => ({
		y: hourGroups[hour],
		name: hour,
		marker: { color: '#bbb' },
		type: "box"
	}));

	return plotData
})

const histogramData = computed(() => {
	if (average_7d_20min.value == null) return null

	const series = average_7d_20min.value.series.find(s =>
		s.type == monthSeries.value.type &&
		s.unit == monthSeries.value.unit &&
		s.sensor == monthSeries.value.sensor
	)

	const plotData = [
		{
			x: series.values,
			type: "histogram",
			marker: {
				color: "#bbb7",
				line: {
					color: "#bbb",
					width: 2
				}
			}
		}
	];

	return plotData
})

onMounted(() => {
	fetchStation()

	fetchAvg30()
	fetchAvg48()
	fetchAvg20()
})
</script>

<style scoped>
.typeSelectButtons .btn {
	margin-right: 5px;
	margin-bottom: 0.2em;
}

.topControls {
	display: flex;
	justify-content: space-between;
}

.topControls > div .btn {
	background-color: #101010;
	/* font-size: medium; */
	border: 1px solid v-bind(uaqiColors[stationData?.uaqi?.main]);
}

.topControls > div .btn:hover {
	background-color: v-bind(uaqiColors[stationData?.uaqi?.main]);
	color: white;
	/* font-size: medium; */
}

.navigationControls .btn + .btn {
	margin-left: 0.5em;
}

.navigationControls {
	display: flex;
}

@media only screen and (max-width: 520px) {
	.navigationControls > .btn > span {
		display: none;
	}
}

.topControls .btn > svg {
	margin-bottom: -0.5px;
}

.topControls .btn > span {
	margin-left: 0.5em;
}

.pollutantsContainer {
	display: flex; 
	flex-direction: row; 
	/* flex-wrap: wrap; */

	overflow-x: auto;
}
</style>