<template>
	<!-- <h3>{{ props.title }}</h3> -->
	<div ref="plot"></div>
</template>

<script setup>
import Plotly from 'plotly.js-dist'
import { ref, defineProps, onMounted, watch, computed } from 'vue'

const props = defineProps({ 
	data: Object,
	layout: Object,
	title: String,
	fixedRange: Boolean
})

const plot = ref(null)

const defaultLayout = {
	autosize: true,
	margin: { t: 10, b: 18, l: 52, r: 5, pad: 0 },
	plot_bgcolor: "#050505",
	paper_bgcolor: "#00000000",
	font: { color: 'white', family: 'monospace' },
	showlegend: false,
	title: null
}

const layout = computed(() => {
	let finalLayout = defaultLayout

	// Merge with default settings
	if (props.layout != null) {
		finalLayout = {...finalLayout, ...props.layout}
	}

	if (props.fixedRange) {
		// Create objects if null
		if (finalLayout.xaxis == null) finalLayout.xaxis = {}
		if (finalLayout.yaxis == null) finalLayout.xaxis = {}

		// Set fixed to both axes
		finalLayout.xaxis.fixedrange = true
		finalLayout.yaxis.fixedrange = true
	}

	return finalLayout
})

const settings = {
	displaylogo: false,
	modeBarButtonsToRemove: [
		'zoom2d', 
		'pan2d', 
		'select2d', 
		'lasso2d', 
		'zoomIn2d', 
		'zoomOut2d', 
		// 'autoScale2d', 
		'resetScale2d'
	]
}

// Plotly animation
// const animation = {
// 	transition: {
// 		duration: 500,
// 		easing: 'cubic-in-out'
// 	},
// 	frame: {
// 		duration: 500
// 	}
// }

function renderChart() {
	Plotly.newPlot(plot.value, props.data, layout.value, settings);
}

function updateChart() {
	Plotly.react(plot.value, props.data, layout.value, settings);
}

onMounted(() => {
	renderChart()
})

watch(props, () => {
	// layout.value.title = props.title
	updateChart()
})
</script>

<style scoped>

</style>